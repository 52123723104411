//import { Counter } from "./components/Counter";
//import { CheckoutTest } from "./components/CheckoutTest";
//import { CheckoutV2 } from "./components/checkoutV2";
import { PunchoutTest } from "./components/PunchoutTest";
import CheckoutHook from "./components/CheckoutHook";
import ConfirmPage  from "./components/ConfirmPage";
import SessionExpired from "./components/SessionExpired";
import HelpCenter from './components/HelpCenter';
import OrderItem from "./components/OrderItem";
import ReturnDetails from "./components/ReturnDetails";
import ConfirmReturn from "./components/ConfirmReturn";
import ContactUs from "./components/ContactUs";
import Faqs from "./components/Faqs";
import ContactConfirm from "./components/ContactConfirm";
import ShippingLabel from "./components/ShippingLabel";
const AppRoutes = [
    {
        index: true,
/*        element: <CheckoutTest />*/
        element: <CheckoutHook />
/*        element: <Home />*/
    },
    //{
    //    path: '/counter',
    //    element: <Counter />
    //},
    {
        path: '/punchout-test',
        element: <PunchoutTest />
    },
    //{
    //    path: '/punchout-test2',
    //    element: <PunchoutTestV2 />
    //},
    //{
    //    path: '/catalog-items',
    //    element: <Items />
    //},
    //{
    //    path: '/checkouttest',
    //    element: <CheckoutTest />
    //},
    //{
    //    path: '/checkoutv2',
    //    element: <CheckoutV2 />
    //},
    {
        path: '/checkouthook',
        element: <CheckoutHook />
    },
    {
        path: '/confirmpage',
        element: <ConfirmPage />
    },
    {
        path: '/sessionexpired',
        element: <SessionExpired />
    },
    {
        path: '/helpcenter',
        element: <HelpCenter />
    },
    {
        path: '/orderitem',
        element: <OrderItem />
    },
    {
        path: '/returndetails',
        element: <ReturnDetails />
    },
    {
        path: '/confirmreturn',
        element: <ConfirmReturn />
    },
    {
        path: '/contactus',
        element: <ContactUs />
    },
    {
        path: '/faqs',
        element: <Faqs />
    },
    {
        path: '/contactconfirm',
        element: <ContactConfirm />
    },
    {
        path: '/label',
        element: <ShippingLabel />
    }
];

export default AppRoutes;
