import React, { Component } from 'react';
import { Link } from "react-router-dom";
export class PunchoutTest extends Component {
    constructor() {
        super();
        this.state = { response: [], clickmsg: [], redirectmsg:[]};
        this.handleClick = this.handleClick.bind(this);
    } 
   
    render() {
        return (
            
            <div>
                <button onClick={this.handleClick}> Initiate request </button>
                <div style={{ whiteSpace: "pre-line" }}>
                     <p>{this.state.clickmsg}</p>
                    <br />
                    <div>{this.state.response}</div>
                    <br />
                      <p>{this.state.redirectmsg}</p>
                </div>
                <Link to="/checkoutTest">CheckoutTest</Link>
            </div>
        );
    }
    handleClick = () => {
        debugger;
        this.setState({ response: '', clickmsg: "Sending setup cxml to Spectrum..." });
        fetch('punchouttest').then(res => res.text()).then(data =>
            this.setState({ response: this.state.response + data, redirectmsg: 'on live, it will be redirect to checkout page' })
        );
    };
}
