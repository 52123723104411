import React, { Component } from 'react';
import { Collapse, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        //if (window.location.origin.indexOf("shop") == -1) {
        //    debugger;
        //    return (
        //        <header>
        //            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
        //                <Navbar.Brand href="#">CHECKOUT</Navbar.Brand>
        //                <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
        //            </Navbar>
        //        </header>
        //        )
        //}
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow" container="true" light="true">
                    <Container>
                        <Navbar.Brand>
                            <img
                                alt="Kawasaki logo"
                                src="/images/Kawasaki_Brand_Logo_Red_Black.png"
                                width="200"
                                height="50"
                                className="d-inline-block align-top main-header-logo"
                            />
                        </Navbar.Brand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>*/}
                                {/*</NavItem>*/}
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>*/}
                                {/*</NavItem>*/}
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>*/}
                                {/*</NavItem>*/}
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={Link} className="text-dark" to="/catalog-items">Catalog Items</NavLink>*/}
                                {/*</NavItem>*/}
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={Link} className="text-dark" to="/checkout">Checkout Page</NavLink>*/}
                                {/*</NavItem>*/}
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={Link} className="text-dark" to="/punchout-test">PunchoutTest</NavLink>*/}
                                {/*</NavItem>*/}
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={Link} className="text-dark" to="/checkoutv2">CheckoutV2</NavLink>*/}
                                {/*</NavItem>*/}
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={Link} className="text-dark" to="/checkouthook">Checkout with Hook</NavLink>*/}
                                {/*</NavItem>*/}
                                {window.location.origin.indexOf("localhost") > -1 && (<NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/helpcenter">Helpcenter</NavLink>
                                </NavItem>)
                                }
                                {window.location.origin.indexOf("localhost") > -1 && (<NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/label">Label</NavLink>
                                </NavItem>)
                                }
                                {/*    */}{/* <NavLink tag={Link} className="text-dark" to="/confirmpage">Confirmation</NavLink>*/}
                                {/*    <NavLink tag={Link} className="text-dark" to={{*/}
                                {/*        pathname: "/confirmpage",*/}
                                {/*        state: { id: "KMC900004" }*/}
                                {/*    }}>Confirmation</NavLink>*/}
                                {/*</NavItem>*/}
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={Link} className="text-dark" to="/sessionexpired">Session Error</NavLink>*/}
                                {/*</NavItem>*/}
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
