import { useEffect } from 'react';

export default function useGooglePayScripts(url) {
    useEffect(() => {
        const head = document.querySelector("head");
        const script = document.createElement("script");
        script.setAttribute("src", url);
        script.async = true;
/*        script.setAttribute("onload", "GooglePayLoaded();");*/
        head.appendChild(script);
    }, [url]);
};

