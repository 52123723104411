import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
export default function ShippingLabel() {
    const [img, setImg] = useState(null);
    useEffect(() => {
        debugger;
        async function fetchData() {
            const queryParameters = new URLSearchParams(window.location.search);
            const id = queryParameters.get("id");
            if (id) {
                const response = await fetch('helpcenter/GetLabel/' + id);
                if (response.status == 200) {
                    const js = await response.json();
                    const base64ImageData = 'data:image/png;base64,' + js.value1;
                    const contentType = 'image/png';

                    const byteCharacters = atob(base64ImageData.substr(`data:${contentType};base64,`.length));
                    const byteArrays = [];

                    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                        const slice = byteCharacters.slice(offset, offset + 1024);

                        const byteNumbers = new Array(slice.length);
                        for (let i = 0; i < slice.length; i++) {
                            byteNumbers[i] = slice.charCodeAt(i);
                        }

                        const byteArray = new Uint8Array(byteNumbers);

                        byteArrays.push(byteArray);
                    }
                    const blob = new Blob(byteArrays, { type: contentType });
                    const blobUrl = URL.createObjectURL(blob);
                    window.location = blobUrl;
                    //window.open(blobUrl, '_blank');
                    //var image = new Image();
                    //image.src = "data:image/jpg;base64," + js.value1;

                    //var w = window.open("");
                    //w.document.write(image.outerHTML);
                    //setImg("data:image/png;base64," + js.value1);
                    //navigate('/helpcenter');
                }
                //const js = await response.json();
                //navigate('/orderitem', { state: { id: js.value1 } });
                //return;
            }
            //else if (id) {
            //    location.state = { id: id };
            //    navigate('/orderitem', location);
            //    return;
            //}
            //if (!location.state || !location.state.id) {
            //    navigate('/helpcenter');
            //    return;
            //}

            //GetOrderInfo();
        }

        fetchData();
    }, [])

    return (

        <Container className="checkout-container main-container">
            <Container className="checkout-container">

                <Card className="confirmation-card mb-6 box-shadow">
                    <Row className="confirm-main-row">
                        <Col sm={12}>
                            <Card.Body>
                                {img && <img src={img} alt="" />}
                               
                            </Card.Body>
                        </Col>
                    </Row>
                </Card>

            </Container>
        </Container>
    );
}
