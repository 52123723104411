import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
export default function ContactConfirm() {

    useEffect(() => {
        document.title = 'Kawasaki.com Help Center';
    }, []);

    const navigateHelp = () => {
        window.location.href = '/helpcenter';
    }
    const location = useLocation();

    return (

        <Container className="checkout-container main-container">
            <Container className="checkout-container">

                <Card className="confirmation-card mb-6 box-shadow">
                    <Row className="confirm-main-row">
                        <Col sm={12}>
                            <Card.Body>
                                <Row className="title-group confirmation-heading">
                                    <Card.Title className="vertical-line heading card-main-title confirm-title" role="heading">YOUR CONTACT REQUEST HAS BEEN SUBMITTED</Card.Title>
                                </Row>
                                <Row className="confirm-text first-confirm">
                                    <p className="confirmation-text confirm-code" aria-level="1" role="heading" aria-label="Ticket Number to be used when contacting support">YOUR TICKET NUMBER IS <span className="ticket-number">{(location.state && location.state.id) ? location.state.id : ''}</span></p>
                                </Row>
                                <Row className="confirm-text contact-text">
                                    <p className="confirmation-text">You will be contacted by a customer service representative as soon as your request has been reviewed.</p>
                                </Row>
                                <Row className="confirm-button first-button">
                                    <Button variant="primary" className="confirm-complete" href={window.location.origin.indexOf("shop") == -1 ? "https://akm-public.kawasaki-global-dev.com/en-us/" : "https://kawasaki.com"}>
                                        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16" role="presentation">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg>
                                        BACK TO <span className="link-decoration">KAWASAKI.COM</span>
                                    </Button>
                                </Row>
                                <Row className="justify-content-center order-item-footer return-confirm-footer">
                                    <Col sm={6}>
                                        <p>Additional Questions?</p>
                                        <p className="green-under clickable" onClick={navigateHelp} tabIndex="1">Visit Our Help Center</p>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Col>
                    </Row>
                </Card>

            </Container>
        </Container>
    );
}
