import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Footer from './Footer.js';
import { useEffect, useState } from "react";

export default function OrderItem() {

    useEffect(() => {
        document.title = 'Kawasaki.com Help Center';
    }, []);

    const description = document.getElementsByClassName('faq-description');
    const arrowDown = document.getElementsByClassName('bi-caret-down-fill');
    const arrowUp = document.getElementsByClassName('bi-caret-up-fill');

    const showFaqs = x => () => {
        description[x].classList.toggle('hide-items')
        arrowDown[x].classList.toggle('hide-items')
        arrowUp[x].classList.toggle('hide-items')
    }

    const [url, setUrl] = useState('orders')
    const [activeTab, setActiveTab] = useState(0);
    const tabs = document.getElementsByClassName('faq-tab-item');
    const textTabs = document.getElementsByClassName('main-tab-group');

    const switchTab = x => () => {
        setActiveTab(x);
    }

    useEffect(() => {
        switch (activeTab) {
            case 0:
                tabs[0].classList.add('faq-active-tab')
                tabs[1].classList.remove('faq-active-tab')
                tabs[2].classList.remove('faq-active-tab')
                tabs[3].classList.remove('faq-active-tab')
                textTabs[0].classList.remove('hide-items')
                textTabs[1].classList.add('hide-items')
                textTabs[2].classList.add('hide-items')
                textTabs[3].classList.add('hide-items')
                break;
            case 1:
                tabs[1].classList.add('faq-active-tab')
                tabs[0].classList.remove('faq-active-tab')
                tabs[2].classList.remove('faq-active-tab')
                tabs[3].classList.remove('faq-active-tab')
                textTabs[1].classList.remove('hide-items')
                textTabs[0].classList.add('hide-items')
                textTabs[2].classList.add('hide-items')
                textTabs[3].classList.add('hide-items')
                break;
            case 2:
                tabs[2].classList.add('faq-active-tab')
                tabs[0].classList.remove('faq-active-tab')
                tabs[1].classList.remove('faq-active-tab')
                tabs[3].classList.remove('faq-active-tab')
                textTabs[2].classList.remove('hide-items')
                textTabs[0].classList.add('hide-items')
                textTabs[1].classList.add('hide-items')
                textTabs[3].classList.add('hide-items')
                break;
            case 3:
                tabs[3].classList.add('faq-active-tab')
                tabs[0].classList.remove('faq-active-tab')
                tabs[1].classList.remove('faq-active-tab')
                tabs[2].classList.remove('faq-active-tab')
                textTabs[3].classList.remove('hide-items')
                textTabs[0].classList.add('hide-items')
                textTabs[1].classList.add('hide-items')
                textTabs[2].classList.add('hide-items')
                break;
            default:
                tabs[0].classList.add('faq-active-tab')
                tabs[1].classList.remove('faq-active-tab')
                tabs[2].classList.remove('faq-active-tab')
                tabs[3].classList.remove('faq-active-tab')
                textTabs[0].classList.remove('hide-items')
                textTabs[1].classList.add('hide-items')
                textTabs[2].classList.add('hide-items')
                textTabs[3].classList.add('hide-items')
                break;
        }
    }, [activeTab, tabs, textTabs])

    const navigateHelp = () => {
        window.location.href = '/helpcenter';
    }

    const navigateContact = () => {
        window.location.href = '/contactus';
    }

    var type = window.location.hash.substring(1);
    useEffect(() => {
        setUrl(type)
        switch (type) {
            default:
                setActiveTab(0)
                break;
            case 'orders':
                setActiveTab(0)
                break;
            case 'returns':
                setActiveTab(1)
                break;
            case 'shipping':
                setActiveTab(2)
                break;
            case 'promocodes':
                setActiveTab(3)
                break;
        }
    }, [url, type])

    return (
        <>
            <Container className="checkout-container main-container">
                <Container>
                    <Card className="help-card">
                        <Row className="title-group title-group-active return-title-row faq-heading">
                            <Col sm={11} className="return-title-col">
                                <Card.Title className="vertical-line heading card-main-title active-heading faqs-title" role="heading">FREQUENTLY ASKED QUESTIONS</Card.Title>
                            </Col>
                            <Col sm={{ span: 1, offset: 0 }}>
                                <p className="underline order-back" onClick={navigateHelp} aria-label="Link to go back to the Help Center page" tabIndex="0" role="navigation">Back</p>
                            </Col>
                        </Row>
                        <Row className="faq-body">
                            <Col sm={3} className="faq-tabs-col">
                                <Card style={{ width: '100%' }} className="faq-tabs sticky">
                                    <ListGroup variant="flush">
                                        <ListGroup.Item className="faq-active-tab clickable faq-tab-item" value={0} onClick={switchTab(0)} role="button" tabIndex="1">
                                            <span className="faq-svg">
                                                <svg version="1.1" focusable="false" x="15px" y="15px" viewBox="0 0 50 50" role="presentation">
                                                    <g id="orders" stroke="none" strokeWidth="1">
                                                        <polygon className="st3" points="13.19,3.53 13.19,8.96 7.48,8.96 "></polygon>
                                                        <path className="st4" d="M-66.52,44.61"></path>
                                                        <path className="st3" d="M40.45,1.75H17.29c-0.82,0-1.48,0.66-1.48,1.48v6.77c0,0.82-0.66,1.48-1.48,1.48H7.46 
                                                                c-0.82,0-1.48,0.66-1.48,1.48v32.49c0,0.82,0.66,1.48,1.48,1.48h32.99c0.82,0,1.48-0.66,1.48-1.48V3.24 
                                                                C41.94,2.42,41.27,1.75,40.45,1.75z M18.17,38.88h-8c-0.64,0-1.15-0.52-1.15-1.15c0-0.64,0.52-1.15,1.15-1.15h8 
                                                                c0.64,0,1.15,0.52,1.15,1.15C19.33,38.37,18.81,38.88,18.17,38.88z M26.34,30.47c0,0.64-0.52,1.15-1.15,1.15H10.18 
                                                                c-0.64,0-1.15-0.52-1.15-1.15v0c0-0.64,0.52-1.15,1.15-1.15h15.01C25.82,29.32,26.34,29.83,26.34,30.47L26.34,30.47z M37.58,24.37 
                                                                H10.18c-0.64,0-1.15-0.52-1.15-1.15s0.52-1.15,1.15-1.15h27.41c0.64,0,1.15,0.52,1.15,1.15S38.22,24.37,37.58,24.37z M37.58,17.11 
                                                                H10.18c-0.64,0-1.15-0.52-1.15-1.15c0-0.64,0.52-1.15,1.15-1.15h27.41c0.64,0,1.15,0.52,1.15,1.15 
                                                                C38.74,16.59,38.22,17.11,37.58,17.11z M37.58,9.85h-17.5c-0.64,0-1.15-0.52-1.15-1.15c0-0.64,0.52-1.15,1.15-1.15h17.5 
                                                                c0.64,0,1.15,0.52,1.15,1.15C38.74,9.33,38.22,9.85,37.58,9.85z">
                                                        </path>
                                                    </g>
                                                </svg>
                                            </span>
                                            ORDERS
                                        </ListGroup.Item>
                                        <ListGroup.Item className="clickable faq-tab-item" value={1} onClick={switchTab(1)} role="button" tabIndex="2">
                                            <span className="faq-svg">
                                                <svg version="1.1" focusable="false" x="15px" y="15px" viewBox="0 0 145 103" role="presentation">
                                                    <g id="Returns_2" className="st0">
                                                        <circle className="st5" cx="73.22" cy="51.5" r="44.48"></circle>
                                                    </g>
                                                    <g id="Returns_1" className="st0">
                                                        <polygon className="st6" points="42.11,49.7 47.32,52.94 63.39,43.71 58.05,40.59 "></polygon>
                                                        <polygon className="st6" points="40.85,51.8 46.36,54.92 46.36,62.05 48.52,61.21 50.5,64.15 50.86,57.43 57.15,61.03 57.15,79.43 40.85,70.26 "></polygon>
                                                        <polygon className="st6" points="51.64,55.28 58.23,59.17 74.09,49.88 67.28,45.93 "></polygon>
                                                        <polygon className="st6" points="59.31,60.79 75.32,51.8 75.32,70.2 59.31,79.43 "></polygon>
                                                        <path className="st6" d="M77.77,28.6v-7.01c-0.18-2.7-2.58-0.3-4.5,1.32c-5.39,4.2-13.97,10.97-13.97,10.97 
                                                                c-1.38,0.84-1.02,2.04,1.02,2.82c3.84,1.98,16.12,9.71,16.12,9.71c2.04,1.32,2.1-0.84,2.1-1.86c-0.12-2.04-0.36-5.99-0.36-5.99 
                                                                c10.85-0.78,17.62,1.02,20.38,8.75c1.08,4.44,1.8,7.13-0.42,11.33c-2.28,3.42-6.29,7.67-12.11,8.63c-4.56,1.56-3.3,3.48,0,3.36 
                                                                c7.13-0.12,16.06-4.56,19.18-12.47C109.3,46.4,105.59,25.24,77.77,28.6z">
                                                        </path>
                                                        <path className="st6" d="M-11.96,0"></path>
                                                    </g>
                                                </svg>
                                            </span>
                                            RETURNS
                                        </ListGroup.Item>
                                        <ListGroup.Item className="clickable faq-tab-item" value={2} onClick={switchTab(2)} role="button" tabIndex="3">
                                            <span className="faq-svg">
                                                <svg version="1.1" x="0px" y="0px" viewBox="0 0 145 103" role="presentation">
                                                    <g id="Shipping">
                                                        <path className="st21" d="M24,78.6h62.8c1,0,1.8-0.8,1.8-1.8V23.8c0-1-0.8-1.8-1.8-1.8H24c-1,0-1.8,0.8-1.8,1.8v52.9C22.2,77.7,23.1,78.6,24,78.6z" />
                                                        <path className="st21" d="M109.4,37.1H94.5c-1.4,0-2.6,1.2-2.6,2.6V76c0,1.4,1.2,2.6,2.6,2.6h25.7c1.4,0,2.6-1.2,2.6-2.6V57c0-0.5-0.1-1-0.4-1.4l-10.8-17.3C111.1,37.6,110.3,37.1,109.4,37.1z" />
                                                        <path className="st22" d="M109,41.9H97.6c-0.8,0-1.4,0.7-1.4,1.5v11.3c0,0.8,0.6,1.5,1.4,1.5h18.2c1.1,0,1.7-1.3,1.2-2.3l-6.9-11.3C109.9,42.2,109.4,41.9,109,41.9z" />
                                                        <circle className="st23" cx="106.2" cy="79.5" r="7.8" />
                                                        <circle className="st21" cx="106.2" cy="79.5" r="3.5" />
                                                        <circle className="st23" cx="68.4" cy="79.5" r="7.8" />
                                                        <circle className="st21" cx="68.3" cy="79.5" r="3.5" />
                                                        <circle className="st23" cx="40" cy="79.5" r="7.8" />
                                                        <circle className="st21" cx="40" cy="79.6" r="3.5" />
                                                    </g>
                                                </svg>
                                            </span>
                                            SHIPPING
                                        </ListGroup.Item>
                                        <ListGroup.Item className="clickable faq-tab-item" value={3} onClick={switchTab(3)} role="button" tabIndex="4">
                                            <span className="faq-svg">
                                                <svg version="1.1" focusable="false" x="15px" y="15px" viewBox="0 0 145 103" role="presentation">
                                                    <g id="Promo_Codes" className="st0">
                                                        <path className="st7" d="M34.87,21.43l-9.72,9.65v33.98l9.72,10.24l84.98,0.13V58.09c-11.87,0.78-13.57-18.26,0-19.3 c-0.13-3.91,0-17.35,0-17.35H34.87z"></path>
                                                        <line className="st8" x1="95.33" y1="22.41" x2="95.33" y2="23.39"></line>
                                                        <line className="st8" x1="95.33" y1="29.59" x2="95.33" y2="34.48"></line>
                                                        <line className="st8" x1="95.33" y1="40.22" x2="95.33" y2="45.11"></line>
                                                        <line className="st8" x1="95.33" y1="51.63" x2="95.33" y2="56.52"></line>
                                                        <line className="st8" x1="95.33" y1="61.74" x2="95.33" y2="66.63"></line>
                                                        <line className="st8" x1="95.33" y1="71.85" x2="95.33" y2="74.29"></line>
                                                        <line className="st8" x1="46.8" y1="62.65" x2="74.85" y2="34.48"></line>
                                                        <circle className="st8" cx="69.56" cy="57.14" r="5.51"></circle>
                                                        <circle className="st8" cx="51.07" cy="39.6" r="5.51"></circle>
                                                    </g>
                                                </svg>
                                            </span>
                                            PROMO CODES
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card>
                            </Col>
                            <Col sm={9} role="contentinfo" aria-label="Main container for FAQs and answers">
                                <div id="orders" className="main-tab-group">
                                    <h5 className="green">ORDERS</h5>
                                    <Row className="faq-row" id="backorders">
                                        <Col className="faq-title" onClick={showFaqs(0)} tabIndex="5">
                                            <h5>BACKORDERS</h5>
                                        </Col>
                                        <Col className="faq-long-col">
                                            <p className="right-text" onClick={showFaqs(0)}>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-down" className="hide-items bi bi-caret-down-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-up" className="bi bi-caret-up-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="faq-description" value={0}>
                                        <p>At this time we cannot accept backorders. Kawasaki Motors Corp., U.S.A. will make every attempt to ensure that our website is as accurate as possible. If we find we do not have the
                                            stock to fill your order, you will receive an email informing you which items have been cancelled and will receive a refund back to your original payment method.</p>
                                    </Row>
                                    <Row className="faq-row">
                                        <Col className="faq-title" onClick={showFaqs(1)} tabIndex="6">
                                            <h5>ORDER STATUS AND TRACKING</h5>
                                        </Col>
                                        <Col className="faq-long-col">
                                            <p className="right-text" onClick={showFaqs(1)}>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-down" className="hide-items bi bi-caret-down-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-up" className="bi bi-caret-up-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="faq-description" value={1}>
                                        <p>To locate your order status, or for any other order related questions, use the Order Lookup on our Help Center <a href="https://shop.kawasaki.com/helpcenter">shop.kawasaki.com/helpcenter</a>.
                                            You will need the order number and email address used to place the order.</p>
                                    </Row>
                                    <Row className="faq-row" id="payment">
                                        <Col className="faq-title" onClick={showFaqs(2)} tabIndex="7">
                                            <h5>PAYMENT AND BILLING</h5>
                                        </Col>
                                        <Col className="faq-long-col">
                                            <p className="right-text" onClick={showFaqs(2)}>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-down" className="hide-items bi bi-caret-down-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-up" className="bi bi-caret-up-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="faq-description" value={2}>
                                        <p>We accept the following methods of payment:</p>
                                        <p className="faq-left">1. <img src="/images/visa.png" alt="Visa icon" className="payment-icon"></img> Visa</p>
                                        <p className="faq-left">2. <img src="/images/mastercard.png" alt="Mastercard icon" className="payment-icon"></img> Mastercard</p>
                                        <p className="faq-left">3. <img src="/images/american-express.png" alt="American Express icon" className="payment-icon amex"></img> American Express</p>
                                        <p className="faq-left">4. <img src="/images/discover.png" alt="Discover icon" className="payment-icon"></img> Discover</p>
                                        {/*<p className="faq-left">5. <img src="/images/apple-pay.svg" alt="Apple Pay icon" className="payment-icon apple"></img> Apple Pay</p>*/}
                                        {/*<p className="faq-left">6. <img src="/images/google-pay.png" alt="Google Pay icon" className="payment-icon google"></img> Google Pay</p>*/}
                                        {/*<p className="faq-left">7. <img src="/images/paypal.jpg" alt="Paypal icon" className="payment-icon paypal"></img> PayPal</p>*/}
                                        {/*<p className="faq-left">8. <img src="/images/click-to-pay.svg" alt="Click to Pay icon" className="payment-icon"></img> Click to Pay</p>*/}
                                        {/*<p className="faq-left">9. <img src="/images/shop-pay.png" alt="Shop Pay icon" className="payment-icon"></img> Shop Pay</p>*/}
                                        {/*<p className="faq-left">10. <img src="/images/amazon-pay.png" alt="Amazon Pay icon" className="payment-icon"></img> Amazon Pay</p>*/}
                                        <p>Charges to your monthly statement will appear as <span className="bold">KMC*KAWASAKI MTR CORP USA</span> or <span className="bold">SES*SPECTRUM ECOMMERCE</span>.</p>
                                        <p>Please contact Kawasaki's authorized payment processor, Spectrum e-Commerce Solutions at <a href="tel:+18555543344">1 (855) 554-3344</a> or at <a href="https://shop.kawasaki.com/support">shop.kawasaki.com/support</a> for any billing questions.</p>
                                    </Row>
                                    <Row className="faq-row" id="itemsforpurchase">
                                        <Col className="faq-title" onClick={showFaqs(3)} tabIndex="8">
                                            <h5>PRODUCTS</h5>
                                        </Col>
                                        <Col className="faq-long-col">
                                            <p className="right-text" onClick={showFaqs(3)}>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-down" className="hide-items bi bi-caret-down-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-up" className="bi bi-caret-up-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="faq-description" value={3}>
                                        <p><span className="bold">1. Items available for purchase:</span></p>
                                        <p className="faq-left">Only accessories, apparel, gifts, service manuals and owner's manuals are available for purchase on our website. Some items, such as oversized items, items sold in package quantities,
                                            items that require special parts for installation, chemicals, cleaners, or other items deemed hazardous are available for purchase only at your local dealer. "Coming Soon" items can
                                            only be added to your Wishlist. If an item is temporarily out of stock, you will only be able to add it to your Wishlist (please check with your local dealer on their availability).
                                            Please read each description thoroughly as some accessories require multiple components to complete which must be purchased separately.</p>
                                        <p><span className="bold">2. Product Questions:</span></p>
                                        <p className="faq-left">For product fitment, installation instructions, and sizing information please refer to individual product descriptions on <a href="https://www.kawasaki.com">www.kawasaki.com</a>. If for some reason you are unable to locate the
                                            answer or have other questions, please contact Kawasaki Customer Relations at <a href="tel:+18668029381">1-866-802-9381</a> . Representatives will be available Monday-Friday 7:00-4:00 PT.</p>
                                    </Row>
                                    {/*<Row className="faq-row" id="salestax">*/}
                                    {/*    <Col className="faq-title" onClick={showFaqs(4)} tabIndex="9">*/}
                                    {/*        <h5>SALES TAX</h5>*/}
                                    {/*    </Col>*/}
                                    {/*    <Col className="faq-long-col">*/}
                                    {/*        <p className="right-text" onClick={showFaqs(4)}>*/}
                                    {/*            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-down" className="hide-items bi bi-caret-down-fill need-help" viewBox="0 0 16 16" role="presentation">*/}
                                    {/*                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />*/}
                                    {/*            </svg>*/}
                                    {/*            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-up" className="bi bi-caret-up-fill need-help" viewBox="0 0 16 16" role="presentation">*/}
                                    {/*                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />*/}
                                    {/*            </svg>*/}
                                    {/*        </p>*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                    {/*<Row className="faq-description" value={4}>*/}
                                    {/*    <p>Taxes are only estimated at the time of checkout. Final taxes will be applied to your payment method at the time of shipment.</p>*/}
                                    {/*</Row>*/}
                                </div>
                                <div id="returns" className="main-tab-group hide-items">
                                    <h5 className="green">RETURNS</h5>
                                    <Row className="faq-row">
                                        <Col className="faq-title" onClick={showFaqs(4)} tabIndex="5">
                                            <h5>RETURNS AND EXCHANGES</h5>
                                        </Col>
                                        <Col className="faq-long-col">
                                            <p className="right-text" onClick={showFaqs(4)}>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-down" className="hide-items bi bi-caret-down-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-up" className="bi bi-caret-up-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="faq-description" value={4}>
                                        <p>We want to make sure you are satisfied with your purchase of Kawasaki Genuine Accessories, Apparel, and Gifts. <span className="bold">If any purchase does not meet your expectations,
                                            you may return it within thirty (30) days of the date it was received.</span>
                                        </p>
                                    </Row>
                                    <Row className="faq-row">
                                        <Col className="faq-title" onClick={showFaqs(5)} tabIndex="6">
                                            <h5>ELIGIBILITY FOR RETURN</h5>
                                        </Col>
                                        <Col className="faq-long-col">
                                            <p className="right-text" onClick={showFaqs(5)}>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-down" className="hide-items bi bi-caret-down-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-up" className="bi bi-caret-up-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="faq-description" value={5}>
                                        <ul>
                                            <li><span className="bold">Eligibility:</span> All returned items must be in original packaging and in new/unused condition. This includes all product tags, polybags, instructions,
                                                hardware, and accessories. Items cannot be damaged due to wear or installation. IF YOUR RETURN DOES NOT MEET THESE CRITERIA, IT MAY BE REJECTED AND RETURNED TO YOU.
                                            </li>
                                            <li><span className="bold">Return Window:</span> Items must be returned within thirty (30) days of the date it was received.</li>
                                            <li><span className="bold">Sale Items:</span> All Sale items are final sale and are not returnable.</li>
                                            <li><span className="bold">Damaged Items:</span> If an order arrives to you with obvious damage, please refuse delivery. If you accept the shipment but find the item damaged, please
                                                follow the return process to initiate a return. Save all packaging material and paperwork for the order, as these are required to make a claim.
                                            </li>
                                            <li><span className="bold">Mis-Shipped Items:</span> If you receive an item that you did not order, please follow the return process to initiate a return.</li>
                                        </ul>
                                    </Row>
                                    <Row className="faq-row">
                                        <Col className="faq-title" onClick={showFaqs(6)} tabIndex="7">
                                            <h5>ELEKTRODE<span style={{ fontFamily: 'Fira Sans' }}>&#174;</span> RETURN POLICY</h5>
                                        </Col>
                                        <Col className="faq-long-col">
                                            <p className="right-text" onClick={showFaqs(6)}>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-down" className="hide-items bi bi-caret-down-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-up" className="bi bi-caret-up-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="faq-description" value={6}>
                                        <ul>
                                            <li>Elektrode&#174; electric bike orders are not returnable online. Please contact our <a href="https://shop.kawasaki.com/contactus">Help Center</a> to initiate a return.</li>
                                            <li>The item must be unused, in its original packaging, and in the same condition as when you received it. Opened/Assembled products are final sale and are not returnable.</li>
                                            <li>A restocking fee of $150.00 will be deducted from your refund. This fee covers the cost of processing the return and restocking the item. Restocking fees may not apply in
                                                cases of manufacturer defects or if the wrong item was shipped.
                                            </li>
                                            <li><span className="bold">Return Window:</span> Items must be returned within thirty (30) days of the date it was received.</li>
                                            <li><span className="bold">Sale Items:</span> All Sale items are final sale and are not returnable.</li>
                                            <li><span className="bold">Damaged Items:</span> If an order arrives to you with obvious damage, please refuse delivery. If you accept the shipment but find the item damaged, please
                                                follow the return process to initiate a return. Save all packaging material and paperwork for the order, as these are required to make a claim.
                                            </li>
                                            <li><span className="bold">Mis-Shipped Items:</span> If you receive an item that you did not order, please follow the return process to initiate a return.</li>
                                        </ul>
                                    </Row>
                                    <Row className="faq-row">
                                        <Col className="faq-title" onClick={showFaqs(7)} tabIndex="8">
                                            <h5>RETURN PROCESS</h5>
                                        </Col>
                                        <Col className="faq-long-col">
                                            <p className="right-text" onClick={showFaqs(7)}>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-down" className="hide-items bi bi-caret-down-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-up" className="bi bi-caret-up-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="faq-description" value={7}>
                                        <ol>
                                            <li>To initiate a return, locate your order using the <span className="bold">Order Lookup</span> on the <a href="https://shop.kawasaki.com/helpcenter">Help Center</a>.
                                                Enter your order number and email address, then click the <span className="bold">Return Item(s)</span> button at the bottom of the order.
                                            </li>
                                            <li>If your purchase qualifies (as determined by the Return eligibility/Return Policy), you will receive an email with a return label and instructions. If email not
                                                received within 2-3 business days, please check your spam filter and/or contact our Help Center.
                                            </li>
                                            <li>Affix the return label to the shipping box, be sure to remove or cover any prior shipping labels.</li>
                                            <li>Please include a copy of the original packing slip in the box. This will help ensure timely processing of your return.</li>
                                            <li>Drop off your package at your nearest UPS drop off location.</li>
                                            <li>Returns will be processed approximately 2-3 weeks from the time you ship your return.</li>
                                        </ol>
                                    </Row>
                                    <Row className="faq-row">
                                        <Col className="faq-title" onClick={showFaqs(8)} tabIndex="9">
                                            <h5>REFUNDS/EXCHANGES</h5>
                                        </Col>
                                        <Col className="faq-long-col">
                                            <p className="right-text" onClick={showFaqs(8)}>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-down" className="hide-items bi bi-caret-down-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-up" className="bi bi-caret-up-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="faq-description" value={8}>
                                        <ul>
                                            <li><span className="bold">Refunds:</span> Upon receipt and processing of the returned item(s), a refund of the purchase price and sales tax (if applicable) will be credited
                                                back to your original form of payment. It may take up to two billing cycles for the credit to appear on your monthly statement.
                                            </li>
                                            <li><span className="bold">Exchanges:</span> At this time, we are unable to process exchanges. However, you may follow the return process to initiate a return, then place a new order.</li>
                                        </ul>
                                    </Row>
                                    <Row style={{ marginTop: '25px' }}>
                                        <p>Kawasaki Motors Corp., U.S.A. reserves the right to modify or update this return policy at any time without prior notice.</p>
                                    </Row>
                                </div>
                                <div id="shipping" className="main-tab-group hide-items">
                                    <h5 className="green">SHIPPING</h5>
                                    <Row className="faq-row">
                                        <Col className="faq-title" onClick={showFaqs(9)} tabIndex="5">
                                            <h5>SHIPPING AND DELIVERY</h5>
                                        </Col>
                                        <Col className="faq-long-col">
                                            <p className="right-text" onClick={showFaqs(9)}>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-down" className="hide-items bi bi-caret-down-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-up" className="bi bi-caret-up-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="faq-description" value={9}>
                                        <p>All shipments fulfilled by Kawasaki will be provided by UPS and/or the USPS. We do not ship on Saturdays, Sundays, or national holidays. We are not responsible for orders after
                                            they have been delivered to the address provided to Kawasaki Motors Corp., U.S.A. during checkout, including any subsequent transfer to, or handling by, a third-party forwarder.</p>
                                    </Row>
                                    <Row className="faq-row">
                                        <Col className="faq-title" onClick={showFaqs(10)} tabIndex="6">
                                            <h5>SHIPPING METHODS</h5>
                                        </Col>
                                        <Col className="faq-long-col">
                                            <p className="right-text" onClick={showFaqs(10)}>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-down" className="hide-items bi bi-caret-down-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-up" className="bi bi-caret-up-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="faq-description" value={10}>
                                        <ol>
                                            <li>Standard - Arrives 5-7 business days after your order has been processed.</li>
                                            <li>Expedited - Arrives 2-4 business days after your order has been processed.</li>
                                        </ol>
                                    </Row>
                                    <Row className="faq-row">
                                        <Col className="faq-title" onClick={showFaqs(11)} tabIndex="7">
                                            <h5>SHIPPING POLICIES</h5>
                                        </Col>
                                        <Col className="faq-long-col">
                                            <p className="right-text" onClick={showFaqs(11)}>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-down" className="hide-items bi bi-caret-down-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-up" className="bi bi-caret-up-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="faq-description" value={11}>
                                        <p>Kawasaki can only ship to addresses within the continental United States and Alaska. In addition, we cannot ship to Hawaii, internationally, APO/FPO/DPO addresses or to PO boxes at this time.</p>
                                    </Row>
                                    <Row className="faq-row">
                                        <Col className="faq-title" onClick={showFaqs(12)} tabIndex="8">
                                            <h5>SHIPPING RATES</h5>
                                        </Col>
                                        <Col className="faq-long-col">
                                            <p className="right-text" onClick={showFaqs(12)}>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-down" className=" hide-items bi bi-caret-down-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-up" className="bi bi-caret-up-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="faq-description" value={12}>
                                        <p>Free shipping will be offered to all orders of $100 or more. Expedited orders, or orders less than $100.00 will be charged based on the total value of your order before tax as follows:</p>
                                        <Table bordered hover responsive>
                                            <thead>
                                                <tr>
                                                    <th>Order Value</th>
                                                    <th>Standard</th>
                                                    <th>Expedited</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>$0 - 39.95</td>
                                                    <td>$6.95</td>
                                                    <td>$31.95</td>
                                                </tr>
                                                <tr>
                                                    <td>$39.96 - 59.95</td>
                                                    <td>$8.95</td>
                                                    <td>$33.95</td>
                                                </tr>
                                                <tr>
                                                    <td>$59.96 - 79.95</td>
                                                    <td>$11.95</td>
                                                    <td>$36.95</td>
                                                </tr>
                                                <tr>
                                                    <td>$79.96 - 99.99</td>
                                                    <td>$13.95</td>
                                                    <td>$38.95</td>
                                                </tr>
                                                <tr>
                                                    <td>$100.00 - 129.95</td>
                                                    <td>Free</td>
                                                    <td>$40.95</td>
                                                </tr>
                                                <tr>
                                                    <td>$129.96 - 149.95</td>
                                                    <td>Free</td>
                                                    <td>$42.95</td>
                                                </tr>
                                                <tr>
                                                    <td>$149.96 - 199.95</td>
                                                    <td>Free</td>
                                                    <td>$44.95</td>
                                                </tr>
                                                <tr>
                                                    <td>$199.96 - 249.95</td>
                                                    <td>Free</td>
                                                    <td>$46.95</td>
                                                </tr>
                                                <tr>
                                                    <td>$249.96 - 299.95</td>
                                                    <td>Free</td>
                                                    <td>$48.95</td>
                                                </tr>
                                                <tr>
                                                    <td>$299.96 - 349.95</td>
                                                    <td>Free</td>
                                                    <td>$50.95</td>
                                                </tr>
                                                <tr>
                                                    <td>$349.96 - 399.95</td>
                                                    <td>Free</td>
                                                    <td>$52.95</td>
                                                </tr>
                                                <tr>
                                                    <td>$399.96 - 449.95</td>
                                                    <td>Free</td>
                                                    <td>$54.95</td>
                                                </tr>
                                                <tr>
                                                    <td>$449.96 - 499.95</td>
                                                    <td>Free</td>
                                                    <td>$56.95</td>
                                                </tr>
                                                <tr>
                                                    <td>$499.96 and UP</td>
                                                    <td>Free</td>
                                                    <td>$58.95</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Row>
                                    <Row className="faq-row" id="shippingnotification">
                                        <Col className="faq-title" onClick={showFaqs(13)} tabIndex="9">
                                            <h5>SHIPPING NOTIFICATION</h5>
                                        </Col>
                                        <Col className="faq-long-col">
                                            <p className="right-text" onClick={showFaqs(13)}>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-down" className="hide-items bi bi-caret-down-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-up" className="bi bi-caret-up-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="faq-description" value={13}>
                                        <p>Once your order has been shipped we will send you an email with the tracking information.</p>
                                    </Row>
                                </div>
                                <div id="promocodes" className="main-tab-group hide-items">
                                    <h5 className="green">PROMO CODES</h5>
                                    <Row className="faq-row">
                                        <Col className="faq-title" onClick={showFaqs(14)} tabIndex="5">
                                            <h5>PROMO CODES CONDITIONS</h5>
                                        </Col>
                                        <Col className="faq-long-col">
                                            <p className="right-text" onClick={showFaqs(14)}>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-down" className="hide-items bi bi-caret-down-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="help-up" className="bi bi-caret-up-fill need-help" viewBox="0 0 16 16" role="presentation">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="faq-description" value={14}>
                                        <ul>
                                            <li>Limit one promo code or coupon per customer.</li>
                                            <li>Offer valid for regular-priced merchandise only; excludes sale, clearance, final-sale, Curtis&reg; Cab merchandise and vehicle accessory packages.</li>
                                            <li>Not valid on gift cards or Kawasaki Protection Plus.</li>
                                            <li>Cannot be used in conjunction with any other coupon or discount.</li>
                                            <li>Cannot be applied to previously placed orders.</li>
                                            <li>Minimum purchase amount must be met before shipping, taxes, and other charges.</li>
                                            <li>Promo code must be presented to dealer, or entered online, prior to completing purchase.</li>
                                            <li>If redeeming online, customer is responsible for correctly entering promo code at the time of purchase. Discount will be reflected on qualifying orders at checkout.</li>
                                            <li>Not transferable or redeemable for cash or credit.</li>
                                            <li>Discount does not apply to shipping (or return shipping) or taxes.</li>
                                            <li>Offers subject to change or expiration at any time without notice and at the sole discretion of Kawasaki.</li>
                                        </ul>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center order-item-footer faq-bottom" aria-label="footer">
                            <Col sm={3}>
                                <p>Still Need Help?</p>
                                <p className="green-under clickable" onClick={navigateContact} tabIndex="10">Contact Us</p>
                            </Col>
                        </Row>
                    </Card>
                </Container>
            </Container>
            <Footer />
        </>
    )
}