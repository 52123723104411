import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Footer from './Footer.js';
import { useEffect, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
export default function HelpCenter() {
    const validationSchema = Yup.object().shape({
        orderNum: Yup.string().required(),
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { control, register, getValues, reset, handleSubmit, formState } = useForm(formOptions);
    //const navigateOrder = (e) => {
    //    e.preventDefault();
    //    window.location.href = '/orderitem';
    //}
    const { errors } = formState;
    const navigateFaqs = () => {
        window.location.href = '/faqs';
    }

    const navigateFaqsOrders = () => {
        window.location.href = '/faqs#orders';
    }

    const navigateFaqsReturns = () => {
        window.location.href = '/faqs#returns';
    }

    const navigateFaqsShipping = () => {
        window.location.href = '/faqs#shipping';
    }

    const navigateFaqsPromos = () => {
        window.location.href = '/faqs#promocodes';
    }
    const [errorMsg, setErrorMsg] = useState('');
    const [validated, setValidated] = useState(null);
    const onSubmit = async (data) => {
        debugger;
        console.log(data);
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify(data)
            };
            const res = await fetch('helpcenter/ValidateOrderSearch', requestOptions);
            if (res.status !== 200) {
                throw new Error(res.statusText);
            }
            const js = await res.json();
            if (js.value1 != "0") {
                setValidated(true);
                navigate('/orderitem', { state: { id: js.value1 } });
                return;
            }
            else {
                setValidated(false);
            }
        } catch (e) {
            console.log(e);
        }
    }
    const navigate = useNavigate();
    useEffect(() => {
        document.title = 'Kawasaki.com Help Center';
    }, []);
    useEffect(() => {
        console.log(new Date())
        debugger;
        if (errors.orderNum || errors.email) {
            setErrorMsg('Please correct the highlighted fields above.');
        }
        else if (validated === false) {
            setErrorMsg('Order not found, please try again.');
        }
        else {
            setErrorMsg('');
        }
    }, [errors, validated]);
    console.log({ errors });

    const currentDate = new Date();
    const futureDate = new Date('2024-07-08');

    return (
        <>
            { currentDate < futureDate ? 
                <div className="holiday-banner">
                    <p>The Help Center will be closed July 4th and 5th for the Independence Day holiday.</p>
                </div>
            :
                <></>
            }

            <Container className="checkout-container main-container help-container">
                <Row className="help-title-row checkout-title-row">
                    <Col className="help-center-title checkout-title">
                        <h1 className="heading card-main-title help-heading">HELP CENTER</h1>
                    </Col>
                    <Col className="checkout-title-under">
                    </Col>
                </Row>
                <Row className="help-title-row">
                    <Col>
                        <h4 className="help-under-title">You have reached the Help Center for Kawasaki Parts and Accessories Orders.</h4>
                    </Col>
                </Row>
                <Container className="home-help-container">
                    <Card className="help-card">
                        <Row>
                            <Col sm={6} className="detail-well help-card-inner">
                                <Row className="help-title">
                                    <Card.Title className="vertical-line heading card-main-title active-heading" role="heading">ORDER SUPPORT</Card.Title>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Card className="clickable" onClick={navigateFaqsOrders} role="button" tabIndex="1">
                                            <div className="card-img-top">
                                                <div className="svg-holder">
                                                    <svg version="1.1" focusable="false" x="15px" y="15px" viewBox="0 0 50 50" role="presentation">
                                                        <g id="orders" stroke="none" strokeWidth="1">
                                                            <polygon className="st3" points="13.19,3.53 13.19,8.96 7.48,8.96 "></polygon>
                                                            <path className="st4" d="M-66.52,44.61"></path>
                                                            <path className="st3" d="M40.45,1.75H17.29c-0.82,0-1.48,0.66-1.48,1.48v6.77c0,0.82-0.66,1.48-1.48,1.48H7.46 
                                                                c-0.82,0-1.48,0.66-1.48,1.48v32.49c0,0.82,0.66,1.48,1.48,1.48h32.99c0.82,0,1.48-0.66,1.48-1.48V3.24 
                                                                C41.94,2.42,41.27,1.75,40.45,1.75z M18.17,38.88h-8c-0.64,0-1.15-0.52-1.15-1.15c0-0.64,0.52-1.15,1.15-1.15h8 
                                                                c0.64,0,1.15,0.52,1.15,1.15C19.33,38.37,18.81,38.88,18.17,38.88z M26.34,30.47c0,0.64-0.52,1.15-1.15,1.15H10.18 
                                                                c-0.64,0-1.15-0.52-1.15-1.15v0c0-0.64,0.52-1.15,1.15-1.15h15.01C25.82,29.32,26.34,29.83,26.34,30.47L26.34,30.47z M37.58,24.37 
                                                                H10.18c-0.64,0-1.15-0.52-1.15-1.15s0.52-1.15,1.15-1.15h27.41c0.64,0,1.15,0.52,1.15,1.15S38.22,24.37,37.58,24.37z M37.58,17.11 
                                                                H10.18c-0.64,0-1.15-0.52-1.15-1.15c0-0.64,0.52-1.15,1.15-1.15h27.41c0.64,0,1.15,0.52,1.15,1.15 
                                                                C38.74,16.59,38.22,17.11,37.58,17.11z M37.58,9.85h-17.5c-0.64,0-1.15-0.52-1.15-1.15c0-0.64,0.52-1.15,1.15-1.15h17.5 
                                                                c0.64,0,1.15,0.52,1.15,1.15C38.74,9.33,38.22,9.85,37.58,9.85z">
                                                            </path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="help-center-card-title">ORDERS</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={6}>
                                        <Card className="clickable" onClick={navigateFaqsReturns} role="button" tabIndex="2">
                                            <div className="card-img-top">
                                                <div className="svg-holder">
                                                    <svg version="1.1" focusable="false" x="15px" y="15px" viewBox="0 0 145 103" role="presentation">
                                                        <g id="Returns_2" className="st0">
                                                            <circle className="st5" cx="73.22" cy="51.5" r="44.48"></circle>
                                                        </g>
                                                        <g id="Returns_1" className="st0">
                                                            <polygon className="st6" points="42.11,49.7 47.32,52.94 63.39,43.71 58.05,40.59 "></polygon>
                                                            <polygon className="st6" points="40.85,51.8 46.36,54.92 46.36,62.05 48.52,61.21 50.5,64.15 50.86,57.43 57.15,61.03 57.15,79.43 40.85,70.26 "></polygon>
                                                            <polygon className="st6" points="51.64,55.28 58.23,59.17 74.09,49.88 67.28,45.93 "></polygon>
                                                            <polygon className="st6" points="59.31,60.79 75.32,51.8 75.32,70.2 59.31,79.43 "></polygon>
                                                            <path className="st6" d="M77.77,28.6v-7.01c-0.18-2.7-2.58-0.3-4.5,1.32c-5.39,4.2-13.97,10.97-13.97,10.97 
                                                                c-1.38,0.84-1.02,2.04,1.02,2.82c3.84,1.98,16.12,9.71,16.12,9.71c2.04,1.32,2.1-0.84,2.1-1.86c-0.12-2.04-0.36-5.99-0.36-5.99 
                                                                c10.85-0.78,17.62,1.02,20.38,8.75c1.08,4.44,1.8,7.13-0.42,11.33c-2.28,3.42-6.29,7.67-12.11,8.63c-4.56,1.56-3.3,3.48,0,3.36 
                                                                c7.13-0.12,16.06-4.56,19.18-12.47C109.3,46.4,105.59,25.24,77.77,28.6z">
                                                            </path>
                                                            <path className="st6" d="M-11.96,0"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                            <Card.Body className="help-text-card">
                                                <Card.Title className="help-center-card-title">RETURNS</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Card className="clickable" onClick={navigateFaqsShipping} role="button" tabIndex="3">
                                            <div className="card-img-top">
                                                <div className="svg-holder">
                                                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 145 103" role="presentation">
                                                        <g id="Shipping">
                                                            <path className="st21" d="M24,78.6h62.8c1,0,1.8-0.8,1.8-1.8V23.8c0-1-0.8-1.8-1.8-1.8H24c-1,0-1.8,0.8-1.8,1.8v52.9C22.2,77.7,23.1,78.6,24,78.6z" />
                                                            <path className="st21" d="M109.4,37.1H94.5c-1.4,0-2.6,1.2-2.6,2.6V76c0,1.4,1.2,2.6,2.6,2.6h25.7c1.4,0,2.6-1.2,2.6-2.6V57c0-0.5-0.1-1-0.4-1.4l-10.8-17.3C111.1,37.6,110.3,37.1,109.4,37.1z" />
                                                            <path className="st22" d="M109,41.9H97.6c-0.8,0-1.4,0.7-1.4,1.5v11.3c0,0.8,0.6,1.5,1.4,1.5h18.2c1.1,0,1.7-1.3,1.2-2.3l-6.9-11.3C109.9,42.2,109.4,41.9,109,41.9z" />
                                                            <circle className="st23" cx="106.2" cy="79.5" r="7.8" />
                                                            <circle className="st21" cx="106.2" cy="79.5" r="3.5" />
                                                            <circle className="st23" cx="68.4" cy="79.5" r="7.8" />
                                                            <circle className="st21" cx="68.3" cy="79.5" r="3.5" />
                                                            <circle className="st23" cx="40" cy="79.5" r="7.8" />
                                                            <circle className="st21" cx="40" cy="79.6" r="3.5" />
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                            <Card.Body className="help-text-card">
                                                <Card.Title className="help-center-card-title">SHIPPING</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={6}>
                                        <Card className="clickable" onClick={navigateFaqsPromos} role="button" tabIndex="4">
                                            <div className="card-img-top">
                                                <div className="svg-holder">
                                                    <svg version="1.1" focusable="false" x="15px" y="15px" viewBox="0 0 145 103" role="presentation">
                                                        <g id="Promo_Codes" className="st0">
                                                            <path className="st7" d="M34.87,21.43l-9.72,9.65v33.98l9.72,10.24l84.98,0.13V58.09c-11.87,0.78-13.57-18.26,0-19.3 c-0.13-3.91,0-17.35,0-17.35H34.87z"></path>
                                                            <line className="st8" x1="95.33" y1="22.41" x2="95.33" y2="23.39"></line>
                                                            <line className="st8" x1="95.33" y1="29.59" x2="95.33" y2="34.48"></line>
                                                            <line className="st8" x1="95.33" y1="40.22" x2="95.33" y2="45.11"></line>
                                                            <line className="st8" x1="95.33" y1="51.63" x2="95.33" y2="56.52"></line>
                                                            <line className="st8" x1="95.33" y1="61.74" x2="95.33" y2="66.63"></line>
                                                            <line className="st8" x1="95.33" y1="71.85" x2="95.33" y2="74.29"></line>
                                                            <line className="st8" x1="46.8" y1="62.65" x2="74.85" y2="34.48"></line>
                                                            <circle className="st8" cx="69.56" cy="57.14" r="5.51"></circle>
                                                            <circle className="st8" cx="51.07" cy="39.6" r="5.51"></circle>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="help-center-card-title">PROMO CODES</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <h4 className="faq-all underline" onClick={navigateFaqs}>SEE ALL FREQUENTLY ASKED QUESTIONS</h4>
                                </Row>
                            </Col>
                            <Col sm={6} className="detail-well help-card-inner">
                                <Row className="help-title">
                                    <Card.Title className="vertical-line heading card-main-title active-heading" role="heading">ORDER LOOKUP</Card.Title>
                                </Row>
                                <Row className="help-order-text">
                                    <p>For Order Status, Tracking, or Returns, enter the order number and email address used to place the order below.</p>
                                </Row>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Row className="help-order-input">
                                        <Controller
                                            control={control}
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <FloatingLabel controlId="floatingOrderNum" label="Order Number" className="mb-3">
                                                    <Form.Control type="text" placeholder="Order Number" onChange={onChange} onBlur={onBlur} value={value} {...register("orderNum")} className={`form-control ${errors.orderNum ? 'is-invalid' : ''}`} />
                                                </FloatingLabel>
                                            )}
                                            name="orderNum"
                                        />

                                    </Row>      
                                    <Row className="help-order-text-help tooltips" tabIndex="5">
                                        <p className="clickable">Where can I find my order number?
                                            <span className="tooltiptext">Your Order Number can be located at the top of the Order Confirmation sent to the email address on your order.</span>
                                        </p>
                                    </Row>
                                    <Row className="help-order-input">
                                        <Controller
                                            control={control}
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <FloatingLabel controlId="floatingEmail" label="Email Address" className="mb-3">
                                                    <Form.Control type="email" placeholder="Email Address" onChange={onChange} onBlur={onBlur} value={value} {...register("email")} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                                </FloatingLabel>
                                            )}
                                            name="email"
                                        />
                                     
                                    </Row>
                                    <Row className="help-order-divider">
                                        <hr></hr>
                                    </Row>
                                    <Row>
                                        {/*{<p className="error-msg" id="error-msg-one">{(errors.firstName || errors.lastName*/}
                                        {/*    || errors.address || errors.city || errors.zipCode || errors.phone || errors.stateKey) ? 'Please correct the highlighted fields above.' : ''}</p>}*/}
                                        {<p className="error-msg" id="error-msg-one">{errorMsg}</p>}
                                    </Row>
                                    <Row className="help-btn">
                                        <Button type="submit" className="secondary btn-secondary" onClick={handleSubmit}>
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill green" viewBox="0 0 16 16" role="presentation">
                                                <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                            </svg>
                                            LOOK UP
                                        </Button>
                                    </Row>
                                </form>
                            </Col>
                        </Row>
                    </Card>
                </Container>
            </Container>
            <Footer />
        </>
    )
}