import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import $ from 'jquery';
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import Footer from './Footer.js';
import useExternalScripts from "./useExternalScripts";
export default function OrderItem() {
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
        fullname: Yup.string().required(),
        message: Yup.string().required(),
        phone: Yup.string().required().transform(value => value.replace(/[^\d]/g, '')).min(10).max(10)
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { control, register, reset, setValue, setError, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    useEffect(() => {
        document.title = 'Kawasaki.com Help Center';
    }, []);

    const navigateHelp = () => {
        window.location.href = '/helpcenter';
    }
    const [errorMsg, setErrorMsg] = useState('');
    const navigate = useNavigate();

    var scriptURL = "https://www.google.com/recaptcha/api.js";
    useExternalScripts(scriptURL);

    const onSubmit = async (data) => {
        // async request which may result error
        debugger;
        try {
            console.log(data);
            let token = $('#g-recaptcha-response').val();
            const err = 'There was an error when sending your message, please try again.';
            if (token == "") {
                setErrorMsg("Failed, Please try again by checking I am not a robot.");
                return;
            }
            const contactUsData = {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({ contactUsForm: data, token: token })
            };
            const res = await fetch('helpcenter/ContactUs', contactUsData);
            const js = await res.json();
            if (res.status !== 200) {
                console.log(js.value1);
                setErrorMsg(err);
                throw new Error(res.statusText);
            }
            else {
                navigate('/ContactConfirm', { state: { id: js.value1 } });
            }
        } catch (e) {
            console.log(e);
            //navigate('/sessionexpired', { state: { id: 2 } });
            return;
        }
    }
    return (
        <>
            <Container className="checkout-container main-container">
                <Container>
                    <Card className="help-card">
                        <Row className="title-group title-group-active return-title-row">
                            <Col sm={4} className="return-title-col">
                                <Card.Title className="vertical-line heading card-main-title active-heading return-title contact-title" role="heading">CONTACT US</Card.Title>
                            </Col>
                            <Col sm={{ span: 1, offset: 7 }}>
                                <p className="underline order-back contact-back" onClick={navigateHelp} aria-label="Link to go back to the Help Center page" tabIndex="1">Back</p>
                            </Col>
                        </Row>
                        <Form className="contact-form" onSubmit={handleSubmit(onSubmit)} id="submitContact" action="?" method="POST">
                            <Row>
                                <Form.Group className="mb-3" controlId="contactUsName" as={Row}>
                                    <Form.Label column sm={{ span: 3, offset: 1 }} lg={{ span: 2, offset: 1 }}>Your Name<span className="red">*</span></Form.Label>
                                    <Col sm={8}>
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <Form.Control type="text" placeholder="Your Name" aria-required="true" onChangeText={onChange} onBlur={onBlur} value={value} {...register("fullname")} className={`form-control ${errors.fullname ? 'is-invalid' : ''}`} maxLength="100" />
                                            )}
                                            name="fullname"                                            
                                        />
                                        
                                    </Col>
                                    <Col sm={1}></Col>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group className="mb-3" controlId="contactUsPhone" as={Row}>
                                    <Form.Label column sm={{ span: 3, offset: 1 }} lg={{ span: 2, offset: 1 }}>Your Phone<span className="red">*</span></Form.Label>
                                    <Col sm={8}>
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <InputMask
                                                    mask="(999) 999-9999"
                                                    value={value}
                                                    type={'text'}
                                                    onChange={onChange}
                                                    {...register("phone")} className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                                                    aria-required="true"
                                                    aria-label="phone"
                                                >
                                                </InputMask>
                                            )}
                                            name="phone"
                                        />
                                    </Col>
                                    <Col sm={2}></Col>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group className="mb-3" controlId="contactUsEmail" as={Row}>
                                    <Form.Label column sm={{ span: 3, offset: 1 }} lg={{ span: 2, offset: 1 }}>Your Email Address<span className="red">*</span></Form.Label>
                                    <Col sm={8}>
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <Form.Control type="text" placeholder="Email Address" aria-required="true" maxLength={50} onChangeText={onChange} onBlur={onBlur} value={value} {...register("email")} className={`form-control ${errors.email ? 'is-invalid' : ''}`} maxLength="50" />
                                            )}
                                            name="email"
                                        />
                                    </Col>
                                    <Col sm={2}></Col>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group className="mb-3" controlId="contactUsMessage" as={Row}>
                                    <Form.Label column sm={{ span: 3, offset: 1 }} lg={{ span: 2, offset: 1 }}>Message<span className="red">*</span></Form.Label>
                                    <Col sm={8}>
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <Form.Control style={{ height: '120px' }} as="textarea" type="text" aria-required="true" placeholder="Your Message" maxLength={50} onChangeText={onChange} onBlur={onBlur} value={value} {...register("message")} className={`form-control ${errors.message ? 'is-invalid' : ''}`} maxLength="500"/>
                                            )}
                                            name="message"
                                        />
                                    </Col>
                                    <Col sm={2}></Col>
                                </Form.Group>
                            </Row>
                            <Row>
                                {<p className="error-msg" id="error-msg-one">{(errors.fullName || errors.phone || errors.email || errors.message) && 'Please correct the highlighted fields above.'}</p>}
                                {<p className="error-msg" id="error-msg-one">{errorMsg}</p>}
                            </Row>
                            <Row className="captcha">
                                <Col xl={{ span: 3, offset: 8 }} lg={{ span: 3, offset: 7 }} md={{ span: 3, offset: 6 }} sm={{ span: 3, offset: 4 }}>
                                    <div className="g-recaptcha" data-sitekey="6LcVUXwoAAAAAOqMeVMImAmVm3RverIT0gJgkAK-"></div>
                                </Col>
                            </Row>
                            <Row className="return-btn">
                                <Col sm={{ span: 3, offset: 9 }} className="contact-us-btn">
                                    <Button variant="primary" className="" onClick={handleSubmit} type="submit" id="btnSubmitStep1" tabIndex="2">
                                        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16" role="presentation">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg>
                                        CONTINUE
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Container>
            </Container>
            <Footer />
        </>
    )
}