import React, { Component, useState, useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import $ from 'jquery';

export default function ConfirmPage() {
    const location = useLocation();

    const navigate = useNavigate();
    const [dealers, setDealers] = useState([]);
    const [fiveDealers, setFiveDealers] = useState([]);
    const [markupLocation, setMarkupLocation] = useState(null);

    let closeDealers;
    let handleChange;

    const dealerButton = useRef(null)

    const dataValue = "final";
    const finalValue = window.dataLayer.find(obj => obj.info === dataValue);

    useEffect(() => {
        if (finalValue && fiveDealers[0]) {
            window.dataLayer.push({
                'event': 'conversion',
                'send_to': 'AW-1020216385/df02CJjJ15cYEMGIveYD',
                'value': finalValue.total,
                'currency': 'USD',
                'transaction_id': location.state.id2 ? location.state.id1 + ', ' + location.state.id2 : location.state.id1
            })

            //Facebook Script
            const script = document.createElement('script');
            let productFinal = [];
            let finalProducts = finalValue.products;

            for (let i = 0; i < finalProducts.length; i++) {
                productFinal.push({
                    'id': finalProducts[i].item_id,
                    'quantity': finalProducts[i].quantity,
                });
            };

            let productsString = productFinal.map(function (obj) {
                return JSON.stringify(obj);
            });

            const scriptText = document.createTextNode(
                `
                fbq('track', 'Purchase', 
                {
                    content_type: 'product',
                    value: ${finalValue.total}, 
                    currency: 'USD',
                    OrderID: '${location.state.id2 ? location.state.id1 + ', ' + location.state.id2 : location.state.id1}',
                    contents: [${productsString}]
                },
                {
                    eventID: '${fiveDealers[0].anonId}_${location.state.id1}'
                });
                `
            );
            script.async = true;
            script.appendChild(scriptText);
            document.head.appendChild(script);

            //TikTok script
            const scriptTiktok = document.createElement('script');
            const contents = finalValue.products.map(item => ({
                content_id: item.item_id,
                content_type: "product",
                content_name: item.item_name,
                quantity: item.quantity
            }));
            const scriptTextTiktok = document.createTextNode(
                `
                ttq.track('CompletePayment', {
                    "contents": ${JSON.stringify(contents)},
                    "value": ${finalValue.total},
                    "currency": "USD"
                });
                `
            );
            scriptTiktok.appendChild(scriptTextTiktok);
            document.head.appendChild(scriptTiktok);

            //Google Tag Script
            const scriptGtag = document.createElement('script');
            const scriptTextGtag = document.createTextNode(
                `
                window.dataLayer = window.dataLayer || [];
                function gtag() { dataLayer.push(arguments); }
                gtag('js', new Date());
                gtag('config', 'G-7Y1E113SES');
            `);
            scriptGtag.appendChild(scriptTextGtag);
            const scriptTextSecond = document.createTextNode(
                `
                gtag('event', 'conversion', {
                    'send_to': 'AW-1020216385/df02CJjJ15cYEMGIveYD'
                });

            `);
            scriptGtag.appendChild(scriptTextSecond);
            document.head.appendChild(scriptGtag);


            //Facebook Ads script
            const scriptAds = document.createElement('script');
            const scriptTextAds = document.createTextNode(
                `
                    !function(s,a,e,v,n,t,z){
                        if(s.saq)return;
                        n=s.saq=function(){
                            n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!s._saq)s._saq=n;
                        n.push=n;
                        n.loaded=!0;
                        n.version='1.0';
                        n.queue=[];
                        t=a.createElement(e);
                        t.async=!0;
                        t.src=v;
                        z=a.getElementsByTagName(e)[0];
                        z.parentNode.insertBefore(t,z)}
                    (window,document,'script','https://tags.srv.stackadapt.com/events.js');
                    saq('ts', 'JWqbFO5iJp5x49d0-KBcbg');
                `
            );
            scriptAds.appendChild(scriptTextAds);
            document.head.appendChild(scriptAds);

            return () => {
                document.head.removeChild(script);
                document.head.removeChild(scriptTiktok);
                document.head.removeChild(scriptGtag);
                document.head.removeChild(scriptAds);
            };
        } else {
            const scriptAds = document.createElement('script');
            const scriptTextAds = document.createTextNode(
                `
                    !function(s,a,e,v,n,t,z){
                        if(s.saq)return;
                        n=s.saq=function(){
                            n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!s._saq)s._saq=n;
                        n.push=n;
                        n.loaded=!0;
                        n.version='1.0';
                        n.queue=[];
                        t=a.createElement(e);
                        t.async=!0;
                        t.src=v;
                        z=a.getElementsByTagName(e)[0];
                        z.parentNode.insertBefore(t,z)}
                    (window,document,'script','https://tags.srv.stackadapt.com/events.js');
                    saq('ts', 'JWqbFO5iJp5x49d0-KBcbg');
                `
            );
            scriptAds.appendChild(scriptTextAds);
            document.head.appendChild(scriptAds);

            return () => {
                document.head.removeChild(scriptAds);
            };
        }
    }, [finalValue, fiveDealers])

    //let showStores;
    let changeDealer = () => {
        debugger;
        if (fiveDealers.length < 5) {
            return;
        }
        setDealers(fiveDealers);
        $('#floatingCloseDealers').val('');
        setMarkupLocation({ lat: fiveDealers[0].latitude, lng: fiveDealers[0].longitude });
        $('#dealer0').prop('checked', true);
        dealerButton.current.style.display = 'none';
    }
    //put this back after testing is done
    useEffect(() => {
        if (!location.state || !location.state.id1) {
            navigate('/sessionexpired', { state: { id: 1 } });
            return;
        }
        GetCloesetDealers();
    }, [location])
    useEffect(() => {
        if (markupLocation) {
            $(`[latitude='${markupLocation.lat}'][longitude='${markupLocation.lng}']`).attr('checked', true);
        }
     }, [markupLocation])
    //const GenerateCXML = async () => {
    //    debugger;
    //    const response = await fetch("checkouth/GenerateCXML/" + location.state.ids);
    //    const myBlob = await response.blob();

    //    const aElement = document.createElement('a');
    //    aElement.setAttribute('download', location.state.id + "_PunchoutOrderRequest");
    //    const href = URL.createObjectURL(myBlob);
    //    aElement.href = href;
    //    aElement.setAttribute('target', '_blank');
    //    aElement.click();
    //    URL.revokeObjectURL(href);
    //};

    const GetCloesetDealers = async () => {
        debugger;
        try {
            const response = await fetch("checkouth/GetClosestDealer/" + location.state.id1);
            if (response.status != 200) {
                throw new Error(response.statusText);
            }
            else {
                const js = await response.json();
                setFiveDealers(js);
                let arr = [];
                arr.push(js[0]);
                setDealers(arr);
                setMarkupLocation({ lat: arr[0].latitude, lng: arr[0].longitude });
                $('#dealer0').prop('checked', true);

            }
        }
        catch (e) {
            console.log(e);
        }
    };

    const handleCheckboxChange = async (event) => {
        debugger;
        let checkedItem = $('#' + event.target.id);
        let x = checkedItem.attr('latitude');
        let y = checkedItem.attr('longitude');
        if (x && y) {
            setMarkupLocation({ lat: parseFloat(x), lng: parseFloat(y) });
        }
        let id = checkedItem.attr('dealerKey');
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({ orderID: location.state.id1, dealerKey: id})
        };
        const resOrder = await fetch('checkouth/SavePreferedDealer', requestOptions);
        if (resOrder.status !== 200) {
            throw new Error(resOrder.statusText);
        }
    }; 
   
    const dealerSearch = async () => {
        debugger;
        try {
            debugger;
            if (fiveDealers.length < 5) {
                return;
            }
            let zipcode = $('#floatingCloseDealers').val().trim();
            if (!zipcode) {
                return;
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({ value1: zipcode, value2: location.state.id1, value3: '' })
            };
            const response = await fetch('checkouth/GetClosestDealerByZip', requestOptions);
            //const response = await fetch("checkouth/GetClosestDealerByZip/" + zipcode);
            if (response.status != 200) {
                throw new Error(response.statusText);
            }
            else {
                const js = await response.json();
                console.log(js);
                setDealers(js);
                setMarkupLocation({ lat: js[0].latitude, lng: js[0].longitude });
                $('#dealer0').prop('checked', true);

            }
        }
        catch (e) {
            console.log(e);
        }

    }; 

    const mapStyles = [
        {
            "featureType": "all",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 13
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#000000"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#3a3a42"
                },
                {
                    "lightness": 14
                },
                {
                    "weight": 1.4
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#212121"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#3a3a42"
                },
                {
                    "lightness": 5
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#4e4e4e"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#4e4e4e"
                },
                {
                    "lightness": 25
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#4e4e4e"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#4e4e4e"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#4e4e4e"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#3a3a42"
                }
            ]
        }
    ]

    const svgMarker = {
        path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
        fillColor: "#66cc33",
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 2,
        anchor: { x: 0, y: 20 }
    };

    /* const myMap = useRef(null);*/
    const MapWithAMarker = withScriptjs(withGoogleMap(props =>
        markupLocation && < GoogleMap
            defaultZoom={10}
            defaultCenter={markupLocation}
            defaultOptions={{ styles: mapStyles, mapTypeControl: false }}
        >
            <Marker
                position={markupLocation}
                icon={svgMarker}
            />
        </GoogleMap> 
    ));
    const GoToOrderDetail = (prm) => {
        debugger;
        if (prm) {
            window.open('/orderitem?newtabid=' + prm , '_blank', 'noopener,noreferrer')
        }
    }
    const GoToOrderDetail1 = () => {
        GoToOrderDetail(location.state.id1);
    }
    const GoToOrderDetail2 = () => {
        GoToOrderDetail(location.state.id2);
    }
    const navigateHelp = () => {
        window.open('/helpcenter', '_blank', 'noopener,noreferrer');
    }
    return (

        <Container className="checkout-container main-container">
            <Container className="checkout-container">

                <Card className="confirmation-card mb-6 box-shadow">
                    <Row className="confirm-main-row">
                        <Col lg={6}>
                            <Card.Body>
                                <Row className="title-group confirmation-heading">
                                    <Card.Title className="vertical-line heading card-main-title confirm-title" role="heading">THANK YOU FOR YOUR ORDER!</Card.Title>
                                </Row>
                                <Row className="confirm-text first-confirm">
                                    {
                                    /*<p className="confirmation-text confirm-code">YOUR ORDER NUMBER IS <span className="order-number" id="transaction-id" tabIndex="1" onClick={GoToOrderDetail}>{location && location.state && location.state.id ? location.state.id : ""}</span></p>*/
                                    location && location.state && location.state.id2 ?
                                    <p className="confirmation-text confirm-code">YOUR ORDER NUMBERS ARE <span className="order-number" id="transaction-id" tabIndex="1" onClick={GoToOrderDetail1}>{location.state.id1}</span>
                                        , <span className="order-number" id="transaction-id" tabIndex="1" onClick={GoToOrderDetail2}>{location.state.id2}</span></p>
                                    :
                                    <p className="confirmation-text confirm-code">YOUR ORDER NUMBER IS <span className="order-number" id="transaction-id" tabIndex="1" onClick={GoToOrderDetail1}>{location && location.state && location.state.id1 ? location.state.id1 : ""}</span></p>
                                    }
                                </Row>
                                <Row className="confirm-text">
                                    {location && location.state && location.state.id2 ?
                                        <p className="confirmation-text">You will receive two separate emails with your order confirmation</p>
                                        :
                                        <p className="confirmation-text">You will receive an email with your order confirmation</p>
                                    }
                                </Row>
                                <Row className="confirm-text second-confirm">
                                    <p className="confirmation-text">Thanks for shopping with Kawasaki!</p>
                                </Row>
                                <Row className="confirm-button first-button">
                                    <Button variant="primary" className="confirm-complete" href={window.location.origin.indexOf("shop") == -1 ? "https://akm-public.kawasaki-global-dev.com/en-us/" : "https://kawasaki.com"} tabIndex="2">
                                        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16" role="presentation">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg>
                                        BACK TO <span className="link-decoration">KAWASAKI.COM</span>
                                    </Button>
                                </Row>
                                <Row className="justify-content-center order-item-footer return-confirm-footer confirm-page-footer">
                                    <Col sm={8}>
                                        <p>Questions about this order?</p>
                                        <p className="green-under clickable" tabIndex="3" onClick={navigateHelp}>Visit Our Help Center</p>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Col>
                        <Col lg={6} className="confirm-second-col">
                            <Row className="title-group title-group-inactive confirm-preferred">
                                <Col xl={9} lg={12} md={9}>
                                    <Card.Title className="heading card-main-title preferred-title" role="heading">YOUR LOCAL KAWASAKI DEALER</Card.Title>
                                </Col>
                                {fiveDealers.length < 5 ? 
                                    <></>
                                :    
                                    <Col xl={3} lg={5} md={3} className="confirm-btn-title">
                                        <Button variant="primary" className="submit right" onClick={changeDealer} ref={dealerButton} type="submit" tabIndex="4">
                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16" role="presentation">
                                                <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                            </svg>
                                            CHANGE DEALER
                                        </Button>
                                    </Col>
                                }
                                <Col sm={12}>
                                    <p>If you need installation support for products on this order, contact your local dealer.</p>
                                </Col>
                            </Row>
                            <Row className="preferred-row">
                                <Col sm={6} className="preferred-col">
                                    {fiveDealers.length < 5 ?
                                        <><br></br></>
                                    :                                    
                                        <Row className="confirm-zip-row keep-layout-confirm">
                                            <Col xl={9} lg={12} sm={9} className="confirm-zip-search">
                                                <FloatingLabel controlId="floatingCloseDealers" label="Enter Your Zip Code" className="mb-3 preferred-form" >
                                                    <Form.Control type="text" placeholder="Enter Your Zip Code" value={closeDealers} onChange={handleChange} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col xl={2} lg={12} sm={3} className="confirm-zip-btn">
                                                <Button className="search-btn confirm-dealers-search" id="searchBtn" onClick={dealerSearch}>
                                                    <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16" role="presentation">
                                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                                    </svg>
                                                </Button>
                                            </Col>
                                        </Row>
                                    }
                                    <div className="dealer-locations" id="shortDealers">
                                        <div>

                                            {dealers ?
                                                dealers.map((d, idx) =>
                                                    <div>
                                                        <Form.Check className="dealer-heading radio-ship" label={<span>{d.name} <div className="dealer-saved"> <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16" role="presentation">
                                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                        </svg> Saved</div></span>} name="shipping_dealer" type="radio" id={"dealer" + idx} value={idx}
                                                            latitude={d.latitude} longitude={d.longitude} onChange={handleCheckboxChange} dealerKey={d.dealerKey} />
                                                        <div className="dealer-info">
                                                            <p>{d.address}</p>
                                                            <p>{d.city}, {d.state}, {d.zip}</p>
                                                            <p>{d.phone}</p>
                                                            <p className="product-lines">{d.products}</p>
                                                        </div></div>) : <></>}
                                        </div>

                                    </div>
                                </Col>
                                <Col sm={6} id="googleMap">
                                    <MapWithAMarker

                                        googleMapURL="https://maps.google.com/maps/api/js?key=AIzaSyBM7UuDtg4DcYZ99_DxFklK2fyoANDbwig&libraries=places,geometry&language=en"
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ height: `515px`, marginTop: '10px', marginBottom: '15px' }} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                    />
                                  
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>

            </Container>
        </Container>
    );
}
