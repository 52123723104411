import React, { Component } from 'react';
//import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';

export default function CheckoutStep1({ onSuccessFn, preloadData, id, onReturnUSStates, shipToDealerID, shipToDealer, setShowShipToD, elektrodeStatus }) {
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
        firstName: Yup.string().trim().required(),
        lastName: Yup.string().trim().required(),
        phone: Yup.string().trim().required().transform(value => value.replace(/[^\d]/g, '')).min(10).max(10),
        shipping_options: Yup.string().required(),
        address: Yup
            .string()
            .when("shipping_options", {
                is: "1",
                then: () => Yup.string().trim().required()
            }),
        city: Yup
            .string()
            .when("shipping_options", {
                is: "1",
                then: () => Yup.string().trim().required()
            }),
        zipCode: Yup
            .string()
            .when("shipping_options", {
                is: "1",
                then: () => Yup.string().trim().required()
            }),
        stateKey: Yup
            .string()
            .when("shipping_options", {
                is: "1",
                then: () => Yup.string().required()
            }),
        dropbox: Yup
            .string().nullable()
            .when("shipping_options", {
                is: "2",
                then: () => Yup.string().required()
            }),
    });
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleSaveContinue = () => {
        let selectedVal = $('input[name="address-options"]:checked').val();
        if (selectedVal != "0") {
            setValue('address', $('#suggested-address' + selectedVal).attr("addressline"));
            setValue('city', $('#suggested-address' + selectedVal).attr("city"));
            setValue('zipCode', $('#suggested-address' + selectedVal).attr("zipcode"));
            setValue('stateKey', $('#suggested-address' + selectedVal).attr("stateKey"));
        }
        setShow(false);
        setAddressValidated(true);
    }

    let closeDealers;

    let shipMyAddress = document.getElementById('ship-my-address')
    let shipDeal = document.getElementById('ship-dealer')
    let shipText = document.getElementById('ship-text')

    let upsDrop = document.getElementById('upsDrop')

    let shipToUps = () => {
        if (shipMyAddress && shipDeal && shipText) {
            shipMyAddress.classList.add('inactive-form')
            shipDeal.classList.remove('inactive-form')
            shipText.classList.add('inactive-form')
        }
        
    }

    let shipToAddress = () => {
        if (shipMyAddress && shipDeal && shipText) {
            shipMyAddress.classList.remove('inactive-form')
            shipDeal.classList.add('inactive-form')
            shipText.classList.remove('inactive-form')
        }

    }
    const decodeHtml = (html) => {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }
    let showStores = () => {
        debugger;
        upsDrop.classList.remove('hide-items');
        getUPSLocation(document.getElementById('txtSearch').value);

    }

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { control, register, reset, setValue ,setError, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const [stateOptions, setStateOptions] = useState([]);
    const [userSession, setuserSession] = useState([]);
    const [locatorRes, setLocatorRes] = useState([]);
    const [locatorLoading, setLocatorLoading] = useState(false);
    const [checkUPS, setCheckUPS] = useState(false);
    const [addressEntered, setAddressEntered] = useState('');
    const [addressSuggested, setAddressSuggested] = useState([]);
    const [addressValidated, setAddressValidated] = useState(false);
    const [addressValidatedMsg, setAddressValidatedMsg] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');

    useEffect(() => {
        if (addressValidated) {
            $('#btnSubmitStep1').click();
        }
    }, [addressValidated]);

    const onSubmit = async (data) => {
        // async request which may result error
        debugger;
        try {
            console.log(data);
            if (!userSession) {
                navigate('/sessionexpired', { state: { id: 2 } });
                return;
            }
            
            let name = '';
            if (data.shipping_options == "2") {
                let upsDropbox = document.getElementById(data.dropbox);
                data.address = upsDropbox.getAttribute("address");
                data.zipCode = upsDropbox.getAttribute("zip");
                data.city = upsDropbox.getAttribute("city");
                data.stateKey = upsDropbox.getAttribute("state");
                name = upsDropbox.getAttribute("name");
            }
            else {

                if (data.zipCode.length == 10 && data.zipCode.indexOf('-') == -1) {
                    //length is 10 but no all numbers
                    setError("zipCode", {
                        type: "manual",
                        message: "Zipcode is invalid.",
                    })
                    return;
                }
                let pattern = new RegExp('\\b[P|p]*(OST|ost)*\\.*\\s*[O|o|0]*(ffice|FFICE)*\\.*\\s*[B|b][O|o|0][X|x]\\b');
                if (pattern.test(data.address)) {
                    setError("address", {
                        type: "manual",
                        message: "PO Boxes are not allowed.",
                    })
                    return;
                }
                data.dropbox = "";
            }
            //call handleShow to show validation msg
            if (!addressValidated && data.shipping_options == "1") {
                const addressValidation = {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                };
                const addressValidationRes = await fetch('checkouth/UPSAddressValidation', addressValidation);
                if (addressValidationRes.status !== 200) {
                    //throw new Error(addressValidationRes.statusText);
                    //let user continue;
                }
                else {
                    try {
                        const vmsg = await addressValidationRes.json();
                        console.log(vmsg);
                        handleShow();
                        let states = document.getElementById('floatingStates');
                        let selectedState = states.options[states.selectedIndex].text;
                        setAddressEntered(data.address + ", " + data.city + " " + selectedState + " " + data.zipCode);
                        if (vmsg.xavResponse && vmsg.xavResponse.response && vmsg.xavResponse.response.responseStatus && vmsg.xavResponse.response.responseStatus.code && vmsg.xavResponse.response.responseStatus.code == "1" && !vmsg.candidate && !vmsg.addressLine) {
                            //no error, no candicate
                            setAddressSuggested([]);
                            handleSaveContinue();
                            return;
                        }
                        else if (vmsg.candidate || vmsg.addressLine) {
                            
                            setAddressValidatedMsg("We couldn't verify the exact address you entered, but did find a close match. Please make sure your mailing address is correct to ensure accurate delivery.");
                            if (vmsg.addressLine) {
                                setAddressSuggested([vmsg]);
                                return;
                            }
                            else {
                                setAddressSuggested(vmsg);
                                return;
                            }
                        }
                        else if (Array.isArray(vmsg)) {
                            setAddressValidatedMsg("We couldn't verify the exact address you entered. Please double check to confirm it's correct or make any necessary changes.");
                            setAddressSuggested(vmsg);
                            return;
                        }
                        else {
                            setAddressValidatedMsg("We couldn't verify the exact address you entered. Please double check to confirm it's correct or make any necessary changes.");
                            setAddressSuggested([]);
                            return;
                        }
                    }
                    catch (e) {

                    }

                }
            }

            setAddressValidated(false);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({ id: userSession, shipping: data, consigneeName: name, shipToDealerID: shipToDealerID })
            };
            const res = await fetch('checkouth/SubmitShipping', requestOptions);
            if (res.status !== 200) {
                throw new Error(res.statusText);
            }
            if (onSuccessFn) {
                debugger;
                const js = await res.json();
                js.shipping_options = data.shipping_options
                onSuccessFn(js);
                onReturnUSStates(stateOptions);
            }

        } catch (e) {
            //navigate('/sessionexpired', { state: { id: 2 } });
            return;
        }
    }

    const fetchStates = async () => {
        const response = await fetch("checkouth/GetStates");
        const states = await response.json();
        setStateOptions(states);
    }
    const getUPSLocation = async (str) => {
        try {
            if (str) {
                setLocatorLoading(true);
                const response = await fetch("checkouth/GetUPSLocator/" + str);


                if (response.status !== 200) {
                    setLocatorRes('');
                    throw new Error(response.statusText);
                }
                const res = await response.json();
                console.log(res);

                if (res.locatorResponse && res.locatorResponse.searchResults && res.locatorResponse.searchResults.dropLocation) {
                    const chunkSize = 3;
                    let cheunks = [];
                    const arr = res.locatorResponse.searchResults.dropLocation;
                    for (let i = 0; i < arr.length; i += chunkSize) {
                        const chunk = arr.slice(i, i + chunkSize);
                        cheunks.push(chunk);
                    }
                    setLocatorRes(cheunks);
                }
            }
            else {
                setLocatorRes('');
            }
            setLocatorLoading(false);
        }
        catch (error) {
            setLocatorLoading(false);
            console.error(error);
        }
    }
    useEffect(() => {
        async function fetchData() {
            if (!preloadData) {
                return;
            }
            await fetchStates();
            setuserSession(id);
            reset({
                firstName: preloadData.firstName,
                lastName: preloadData.lastName,
                address: preloadData.address,
                city: preloadData.city,
                zipCode: preloadData.zipCode,
                phone: preloadData.phone,
                stateKey: preloadData.stateKey == "0" ? "" : preloadData.stateKey,
                email: preloadData.email,
                shipping_options: preloadData.shipping_options,
                dropbox: preloadData.dropbox

            })
            setCheckUPS(preloadData.shipping_options == "2");
            if (checkUPS) {
                showStores();
            }
            else {
                shipToAddress();
            }
        }
        fetchData();
    }, [preloadData])
    useEffect(() => {
        if (addressSuggested) {
            $('#bodySuggested').show();
        }
        else {
            $('#bodySuggested').hide();
        }
      
    }, [addressSuggested])
    
/*    console.log({ errors });*/
    const shipping_options = register("shipping_options");
    const delivery_options = register("delivery_options");
    return (
        <form className="shipping-form active-form" onSubmit={handleSubmit(onSubmit)} autocomplete="off">
            <Row>
                <h4 className="heading">CUSTOMER CONTACT INFORMATION</h4>
            </Row>
            <Row>
                <Col sm={12}>
                    <p className="lighter-text spacing-left">Required Fields*</p>
                </Col>
            </Row>
            <Row className="group">
                <Col sm={5}>
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <FloatingLabel controlId="floatingFirst" label="First Name*" className="mb-3" >
                                <Form.Control type="text" placeholder="First Name*" onChange={setFirstName()} value={firstName} {...register("firstName")} className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} maxLength="20" />
                            </FloatingLabel>
                        )}
                        name="firstName"
                        aria-required="true"
                    />
                    {errors.firstName && <p>{errors.firstName.message}</p>}

                </Col>
                <Col sm={5}>
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <FloatingLabel controlId="floatingLast" label="Last Name*" className="mb-3" >
                                <Form.Control type="text" placeholder="Last Name*" onChange={setLastName()} value={lastName} {...register("lastName")} className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} maxLength="20" />
                            </FloatingLabel>
                        )}
                        name="lastName"
                        aria-required="true"
                    />

                </Col>
            </Row>
            <Row className="group bottom-group">
                <Col sm={5}>
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <FloatingLabel controlId="floatingEmail" label="Email*" className="mb-3" >
                                <Form.Control type="text" placeholder="Email*" onChange={setEmail()} value={email} {...register("email")} className={`form-control ${errors.email ? 'is-invalid' : ''}`} maxLength="50" />
                            </FloatingLabel>
                        )}
                        name="email"
                        aria-required="true"
                    />


                </Col>
                <Col sm={5}>
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value } }) => (
                            <FloatingLabel controlId="floatingPhone" label="Phone*" className="mb-3" >
                                <InputMask
                                    mask="(999) 999-9999"
                                    value={value}
                                    type={'text'}
                                    onChange={onChange}
                                    autoComplete="new-password"
                                    {...register("phone")} className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                                >
                                </InputMask>
                            </FloatingLabel>
                        )}
                        name="phone"
                        aria-required="true"
                    />

                </Col>
            </Row>
            <input {...register("delivery_options")} type="hidden" />
            <div id="ship-my-address" className="active-form">
                <h4 className="heading">CUSTOMER SHIPPING ADDRESS</h4>
                {elektrodeStatus ? 
                    <Row style={{paddingLeft: 10, paddingRight: 10, marginBottom: 10}}>
                        <Col sm={10}>
                            <Row className="dealer-ship-info keep-layout">
                                {shipToDealer && shipToDealer.address !== '' ?
                                    <>
                                        <Col sm={12}>
                                            <p className="shipping-to" style={{fontSize: 16}}>Ship to Dealer Selected:</p>
                                        </Col>
                                        <Col sm={9} lg={8} xl={9} xs={8}>
                                            <p className="item-title item-name" style={{ fontSize: 20 }}>{shipToDealer ? shipToDealer.name : ""}</p>
                                            <p className="item-desc quantity" style={{ fontSize: 16 }}>{shipToDealer ? shipToDealer.address + ", " + shipToDealer.city + ", " + shipToDealer.state + ", " + shipToDealer.zip : ""}</p>
                                        </Col>
                                        <Col sm={3} lg={4} xl={3} xs={4}>
                                            <Button variant="secondary" onClick={() => setShowShipToD(true)}>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill green" viewBox="0 0 16 16" role="presentation">
                                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                </svg>
                                                EDIT
                                            </Button>
                                        </Col>
                                        <Col sm={12} style={{marginTop: 15}}>
                                            <p className="lighter-text">*Elektrode® electric bike  orders are eligible for Ship to Dealer for free assembly and configuration. All other parts and accessories will be shipped directly to the customer shipping address. Customer shipping address is still required, even if you have selected Ship to Dealer.</p>
                                        </Col>
                                    </>
                                :    
                                    <>
                                        <Col sm={3} lg={4} xl={3} xs={4} style={{marginTop: 15}}>
                                            <Button variant="secondary" onClick={() => setShowShipToD(true)}>
                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill green" viewBox="0 0 16 16" role="presentation">
                                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                </svg>
                                                Ship to Dealer
                                            </Button>
                                        </Col>
                                        <Col sm={12} style={{ marginTop: 15 }}>
                                            <p className="lighter-text">*Elektrode® electric bike  orders are eligible for Ship to Dealer for free assembly and configuration. All other parts and accessories will be shipped directly to the customer shipping address. Customer shipping address is still required, even if you have selected Ship to Dealer.</p>
                                        </Col>
                                    </>    
                                }
                            </Row>
                        </Col>
                    </Row>
                :
                    <></>
                }
                <Row>
                    <Col sm={12}>
                        <p className="lighter-text" id="ship-text">(**Orders do not deliver to P.O. Boxes)</p>
                    </Col>
                </Row>
                <Row className="group">
                    <Col sm={5}>
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <FloatingLabel controlId="floatingAddress" label="Address*" className="mb-3" >
                                    <Form.Control type="text" placeholder="Address*" onChange={setAddress()} value={address} {...register("address")} className={`form-control ${errors.address ? 'is-invalid' : ''}`} maxLength="100" />
                                </FloatingLabel>
                            )}
                            name="address"
                            aria-required="true"
                        />
                    </Col>
                    <Col sm={5}>
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <FloatingLabel label="City*" className="mb-3" >
                                    <Form.Control type="text" placeholder="City*" onChange={setCity()} value={city} {...register("city")} className={`form-control ${errors.city ? 'is-invalid' : ''}`} maxLength="100" />
                                </FloatingLabel>
                            )}
                            name="city"
                            aria-required="true"
                        />

                    </Col>
                </Row>
                <Row className="group">
                    <Col sm={5}>
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <FloatingLabel controlId="floatingStates" label="State*" className="mb-3" aria-label="Select a state">
                                    <Form.Control
                                        as="select"
                                        custom
                                        {...register("stateKey")} className={`form-control ${errors.stateKey ? 'is-invalid' : ''}`}
                                        onChange={setState()} value={state}
                                    >
                                        <option value="">Select a state</option>
                                        {stateOptions.map(op => (
                                            <><option value={op.value}>{op.label}</option></>
                                        ))}
                                    </Form.Control>
                                </FloatingLabel>
                            )}
                            name="stateKey"
                            aria-required="true"
                        />

                    </Col>
                    <Col sm={5}>
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <FloatingLabel controlId="floatingZipCode" label="Zip Code*" className="mb-3" >
                                    <Form.Control type="text" placeholder="Zip Code*" onChange={setZipCode()} value={zipCode} {...register("zipCode")} className={`form-control ${errors.zipCode ? 'is-invalid' : ''}`} maxLength="10" />
                                </FloatingLabel>
                            )}
                            name="zipCode"
                            aria-required="true"
                        />


                    </Col>
                </Row>

                {/*<Form.Group className="group mb-3">*/}
                {/*    <Form.Check className="heading email-checkbox checkbox spacing-left" type="checkbox" id="emailUpdates" label="SUBSCRIBE TO KAWASAKI EMAIL UPDATES" {...register("subscribe")} />*/}
                {/*</Form.Group>*/}
            </div>
            <div id="ship-dealer" className="inactive-form">
                <Row>
                    {errors.dropbox ? <Col sm={6} className="select-drop">
                        <p className="lighter-text red">Please select a UPS Access Point</p>
                    </Col> : <></>}
                </Row>
                <Row className="group">
                    <Col lg={5} sm={10} xs={11} className="dealer-search">
                        <FloatingLabel controlId="floatingCloseDealers" label="Enter Your Address or Zip Code*" className="mb-3">
                            <Form.Control type="text" id="txtSearch" placeholder="Enter Your Address or Zip Code*" value={closeDealers} />
                        </FloatingLabel>
                    </Col>
                    <Button className="search-btn" id="searchBtn" onClick={showStores}>
                        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16" role="presentation">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                    </Button>
                </Row>

                <Modal show={show} onHide={handleClose} size="lg" centered>
                    <Modal.Header closeButton closeVariant="white"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                        <Modal.Title>Confirm your address</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{addressValidatedMsg}</Modal.Body>
                    {/*<Modal.Body>*/}
                    {/*    <h5>ADDRESS ENTERED:</h5>*/}
                    {/*    <Row className="address-modal-row">*/}
                    {/*        <Form.Check className="radio-ship" defaultChecked label={addressEntered} name="address-options" type="radio" id="wrong-address" value={0} />*/}

                    {/*    </Row>*/}
                    {/*</Modal.Body>*/}
                    <Modal.Body id="bodySuggested">
                        <h5>SUGGESTED ADDRESS:</h5>
                        <Row className="address-modal-row">
                            {addressSuggested ? addressSuggested.map((d, idx) =>
                                <Form.Check className="radio-ship" defaultChecked label={`${d.addressLine}, ${d.politicalDivision2} ${d.politicalDivision1} ${d.postcodePrimaryLow}-${d.postcodeExtendedLow}`} name="address-options" type="radio" id={`suggested-address${idx + 1}`}
                                    value={idx + 1}
                                    addressLine={d.addressLine} city={d.politicalDivision2} stateKey={d.stateKey} zipcode={`${d.postcodePrimaryLow}-${d.postcodeExtendedLow}`} />) : <></>}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row className="modal-footer-buttons">
                            <Col sm={6}>
                                <Button variant="tertiary" onClick={handleClose} className="modal-footer-first-btn">
                                    <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill green" viewBox="0 0 16 16" role="presentation">
                                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                    </svg>
                                    EDIT ADDRESS
                                </Button>
                            </Col>
                            <Col sm={6}>
                                <Button variant="secondary" onClick={handleSaveContinue}>
                                    <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill green" viewBox="0 0 16 16" role="presentation">
                                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                    </svg>
                                    SAVE & CONTINUE
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>

                <div id="upsDrop" className="hide-items">
                    <p>Choose Your Closest UPS Drop Box:</p>
                    <Row>
                        <Col sm={12} className="dealer-div-holder">
                            {locatorLoading ? <Row className="item-holder">  <Col sm={12}>
                                <div className="dealer-results" id="noResults">
                                    <p className="dealer-heading">Loading</p>
                                </div></Col> </Row> :
                                locatorRes ? locatorRes.map((chunk) =>
                                    <Row className="item-holder">
                                        {chunk.map((d) =>
                                            <Col sm={4}>
                                                <Form.Check inline className="dealer-heading radio-ship" label="UPS Drop Box" name="shipping_dealer" type="radio" value={d.locationID} {...register("dropbox")} />
                                                <div className="dealer-info">
                                                    <p className="uppercase">{d.addressKeyFormat.consigneeName}</p>
                                                    <p className="uppercase" id={d.locationID} address={d.addressKeyFormat.addressLine} city={d.addressKeyFormat.politicalDivision2}
                                                        state={d.addressKeyFormat.politicalDivision1} zip={d.addressKeyFormat.postcodePrimaryLow} phone={d.phoneNumber} name={d.addressKeyFormat.consigneeName}>
                                                        {decodeHtml(d.addressKeyFormat.addressLine)}, {d.addressKeyFormat.politicalDivision2}, {d.addressKeyFormat.politicalDivision1}, {d.addressKeyFormat.postcodePrimaryLow}</p>
                                                    <p>{d.distance.value} {d.distance.unitOfMeasurement.code.toLowerCase()}</p>
                                                </div>
                                            </Col>)}
                                    </Row>
                                ) : <Row className="item-holder">  <Col sm={12}>
                                    <div className="dealer-results" id="noResults">
                                        <p className="dealer-heading">No Results Found</p>
                                    </div></Col> </Row>}

                        </Col>
                    </Row>
                </div>
            </div>
            <Row>
                {<p className="error-msg" id="error-msg-one">{(errors.firstName || errors.lastName
                    || (errors.address && errors.address.type != "manual") || errors.city || (errors.zipCode && errors.zipCode.type != "manual") || errors.phone || errors.stateKey) ? 'Please correct the highlighted fields above.' : ''}</p>}
                {<p className="error-msg" id="error-msg-one">{(errors.address && errors.address.message) ? errors.address.message : ''}</p>}
                {<p className="error-msg" id="error-msg-one">{(errors.zipCode && errors.zipCode.message) ? errors.zipCode.message : ''}</p>}

            </Row>

            <Button variant="primary" className="continue right" onClick={handleSubmit} type="submit" id="btnSubmitStep1">
                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16" role="presentation">
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                </svg
                >CONTINUE
            </Button>
        </form>

    )
}