//import React, { Component } from 'react';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import PulseLoader from "react-spinners/PulseLoader";
import { useEffect, useState, useRef } from "react";
//import HookTest from './HookTest';
import CheckoutStep1 from './chekout/CheckoutStep1';
import CheckoutStep2 from './chekout/CheckoutStep2';
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
//export class CheckoutHook extends Component {
export default function CheckoutHook() {
    //const [Shipping, setShipping] = useState({ firstName: '', lastName: '', address: '', email: '', phone: '', cityStateZip: '' });
    const [Shipping, setShipping] = useState({});
    const [BuyerCoockie, setBuyerCoockie] = useState('');
    const [stateOptions, setStateOptions] = useState([]);
    const [freightFee, setFreightFee] = useState([]);
    const [loggedin, setLoggedin] = useState(false);
    const defaultPrice = "$0.00";
    const [shipToDealer, setShipToDealer] = useState({
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        dealerid: ''
    });
    const [elektrodeOrders, setElektrodeOrders] = useState([]);
    const [elektrodeStatus, setElektrodeStatus] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [errorMsgShipToD, setErrorMsgShipToD] = useState('');
    //const [dealerAddress, setDealerAddress] = useState('');
    const [dealers, setDealers] = useState('');
    const [OrderInfo, setOrderInfo] = useState({
        subtotal: defaultPrice,
        total: defaultPrice,
        tax: defaultPrice,
        shippingFee: defaultPrice,
        freeShipping: false,
        promoCode: "",
        promoCodeDesc: "",
        promoDiscount: "",
        submitted: false,
        orderitems: null
    });
    const navigate = useNavigate();

    let code = new URLSearchParams(window.location.search);
    const query = code.get('id');

    let testFuse = '@gofuse.com';
    let testSes = '@ses-bms.com';

    useEffect(() => {
        const getOrder = () => {
            let products = [];
            products.push(OrderInfo.orderitems);
            products = products[[0]];
            let googleProducts = [];
            let price = [];
            let productIds = [];
            let subtotal = OrderInfo.subtotal;
            let total = OrderInfo.total;
            let subNum = parseFloat(subtotal.replace(/[^0-9.]/g, ''));
            let totalNum = parseFloat(total.replace(/[^0-9.]/g, ''));
            if (products && products.length) {
                for (let i = 0; i < products.length; i++) {
                    price.push(products[i].unitPrice);
                    productIds.push(products[i].partID);
                    googleProducts.push({
                        item_id: productIds[i].replace(/-/g, ""),
                        item_name: products[i].description,
                        price: parseFloat(price[i].replace(/[^0-9.]/g, '')),
                        quantity: parseFloat(products[i].qty)
                    });
                }
                if (window.dataLayer.some(e => e.event === 'begin_checkout')) {
                    if (Shipping.email) {
                        if (!Shipping.email.includes(testFuse) && !Shipping.email.includes(testSes)) {
                            window.dataLayer.push({
                                'info': "final",
                                'products': googleProducts,
                                'value': subNum,
                                'coupon': OrderInfo.promoCode,
                                'shipping': parseFloat(OrderInfo.shippingFee.substring(1)),
                                'tax': parseFloat(OrderInfo.tax.substring(1)),
                                'total': totalNum
                            });
                        }
                    }
                } else {
                    window.dataLayer.push({
                        'event': "begin_checkout",
                        'products': googleProducts,
                        'value': subNum,
                        'coupon': OrderInfo.promoCode,
                        'shipping': parseFloat(OrderInfo.shippingFee.substring(1)),
                        'tax': parseFloat(OrderInfo.tax.substring(1))
                    });
                }
            } else {
                console.log('error')
            }
        }
        getOrder();
        tiktokPixel();
    }, [OrderInfo, testFuse, testSes])

    const facebookPixel = function (email, first, last, phone, city, state, zip, id) {
        const script = document.createElement('script');
        const scriptText = document.createTextNode(
            `
            !function (f, b, e, v, n, t, s) {
            if (f.fbq) return; n = f.fbq = function () {
                n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            }; if (!f._fbq) f._fbq = n;
            n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
            t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
            }(window,
                document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
            fbq('dataProcessingOptions', ['LDU'], 0, 0);
            fbq('init', '978952395524553',
                {
                    em: '${email}',
                    fn: '${first}',
                    ln: '${last}',
                    ph: '${phone}',
                    ct: '${city}',
                    st: '${state}',
                    zp: '${zip}',
                    country: 'us',
                    external_id: '${id}'
                }
            );
            //Values will be hashed automatically by the pixel using SHA-256
            fbq('track', 'PageView');
            `);
        script.appendChild(scriptText);
        document.head.appendChild(script);
        return () => {
            document.head.removeChild(script);
        }
    }

    const tiktokPixel = () => {
        const script = document.createElement('script');
        let subtotal = OrderInfo.subtotal;
        let subNum = parseFloat(subtotal.replace(/[^0-9.]/g, ''));
        if (OrderInfo.orderitems) {
            const contents = OrderInfo.orderitems.map(item => ({
                content_id: item.partID,
                content_type: "product",
                content_name: item.description,
                quantity: parseFloat(item.qty)
            }));
            const scriptText = document.createTextNode(
                `
                ttq.track('InitiateCheckout', {
                    "contents": ${JSON.stringify(contents)},
                    "value": ${subNum},
                    "currency": "USD"
                });
                `
            );
            script.appendChild(scriptText);
            document.head.appendChild(script);
            console.log(OrderInfo.orderitems)
            return () => {
                document.head.removeChild(script);
            }
        };
    }

    useEffect(() => {
        const script = document.createElement('script');
        const scriptText = document.createTextNode(
            `
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'G-7Y1E113SES');
        `);
        script.appendChild(scriptText);
        const scriptTextSecond = document.createTextNode(
            `
            gtag('event', 'conversion', {
                'send_to': 'AW-1020216385/uJsVCJrs4pcYEMGIveYD'
            });
        `);
        script.appendChild(scriptTextSecond);
        document.head.appendChild(script);
        return () => {
            document.head.removeChild(script);
        };
    })

    const onCheckoutStep1Success = (data) => {
        setShipping(data);
        setOrderInfo({
            subtotal: data.subtotal, total: data.total, tax: data.tax, shippingFee: data.shippingFee,
            dShippingFee: data.dShippingFee, dSubtotal: data.dSubtotal, dTax: data.dTax, dTotal: data.dTotal,
            shipping_options: data.shipping_options,
            orderitems: OrderInfo.orderitems,
            freeShipping: OrderInfo.freeShipping,
            promoCode: OrderInfo.promoCode,
            promoCodeDesc: OrderInfo.promoCodeDesc,
            promoDiscount: OrderInfo.promoDiscount,
            dPromoDiscount: OrderInfo.dPromoDiscount

        });
        facebookPixel(data.email, data.firstName, data.lastName, data.phone, data.city, data.stateCode, data.zipCode, query);
        if (loggedin) {
            document.getElementById('divSignin').classList.add('hide-items');
        }
        else {
            document.getElementById('divSignin').classList.remove('hide-items');
        }
        jumpToStep2();
    }
    const jumpToStep2 = () => {
        let reviewTitle = document.getElementById('review-title');
        let reviewBody = document.getElementById('review-body')
        let shippingTitle = document.getElementById('shipping-title')
        let shippingBody = document.getElementById('shipping-body')
        reviewTitle.classList.remove('title-group-inactive')
        reviewTitle.classList.add('title-group-active')
        reviewBody.classList.remove('inactive-body')
        shippingBody.classList.add('inactive-body')
        shippingTitle.classList.remove('title-group-active')
        shippingTitle.classList.add('title-group-inactive')
        shippingTitle.classList.add('clickable')
    }
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const onShippingFeeChangeFn = (data) => {
        let shipping = data == "1" ? freightFee[0] : freightFee[1];
        let discount = OrderInfo.dPromoDiscount;
        let subtotal = OrderInfo.dSubtotal;
        let tax = OrderInfo.dTax;
        let total = shipping + subtotal + tax - discount;
        setOrderInfo({
            subtotal: formatter.format(subtotal), total: formatter.format(total), tax: formatter.format(tax), shippingFee: formatter.format(shipping),
            dShippingFee: shipping, dSubtotal: subtotal, dTax: tax, dTotal: total, shipping_options: OrderInfo.shipping_options,
            orderitems: OrderInfo.orderitems,
            freeShipping: OrderInfo.freeShipping,
            promoCode: OrderInfo.promoCode,
            promoCodeDesc: OrderInfo.promoCodeDesc,
            promoDiscount: OrderInfo.promoDiscount,
            dPromoDiscount: OrderInfo.dPromoDiscount
        });
    }

    const onUpdateStep2States = (data) => {
        setStateOptions(data);
    }

    const [pop, setPop] = useState(false);
    const handlePop = () => setPop(true);
    const handleClose = () => setPop(false);
    const [showShipToD, setShowShipToD] = useState(false);
    const handleCloseShipToD = () => setShowShipToD(false);
    const handleShowShipToD = () => setShowShipToD(true);
    const getId = () => {
        const queryParameters = new URLSearchParams(window.location.search);
        const id = queryParameters.get("id");
        if (!id) {
            //navigate('/confirmpage', { state: { id1: 'KMC900165', id2:'' } });
            navigate('/sessionexpired', { state: { id: 1 } });
            return;
        }
        setBuyerCoockie(id);
    }
    const getOrderInfo = async () => {
        try {
            const response = await fetch("checkouth/GetOrderInfo/" + BuyerCoockie);
            handlePop();
            if (response.status != 200) {
                let type = response.status == 404 ? 1 : 2
                handleClose();
                navigate('/sessionexpired', { state: { id: type } });
                return;
            }
            const ord = await response.json();
            setOrderInfo(ord);
            setFreightFee(ord.freightFee);
            if (ord && ord.loggedin) {
                setLoggedin(true);
                document.getElementById('divSignin').classList.add('hide-items');
            }
            else {
                setLoggedin(false);
                document.getElementById('divSignin').classList.remove('hide-items');
            }
            handleClose();
            //ship to store code
            //let eOrders = [];
            //ord.orderitems.forEach((itm) => {
            //    if (itm.isElektrode) {
            //        eOrders.push(itm);
            //    }
            //});
            //if (eOrders.length > 0) {
            //    setElektrodeOrders(eOrders);
            //    setElektrodeStatus(true);
            //    handleShowShipToD();
            //}
        }
        catch (e) {
            navigate('/sessionexpired', { state: { id: 2 } });
            return;
        }

    }

    useEffect(() => {
        async function fetchData() {
            await getId();
            if (BuyerCoockie) {
                getOrderInfo();
            }
        }
        fetchData();
    }, [BuyerCoockie])

    useEffect(() => {
        if (OrderInfo && OrderInfo.submitted && OrderInfo.dTotal) {

            setShipping({
                firstName: OrderInfo.firstName,
                lastName: OrderInfo.lastName,
                address: OrderInfo.address,
                city: OrderInfo.city,
                zipCode: OrderInfo.zipCode,
                phone: OrderInfo.phone,
                stateKey: OrderInfo.stateKey,
                email: OrderInfo.email,
                cityStateZip: OrderInfo.cityStateZip,
                delivery_options: OrderInfo.delivery_options,
                shipping_options: OrderInfo.shipping_options,
                dTotal: OrderInfo.dTotal
            });
            document.getElementById('divSignin').classList.add('hide-items');
            jumpToStep2();
        }
        else if (loggedin) {
            document.getElementById('divSignin').classList.add('hide-items');
        }
        else {
            document.getElementById('divSignin').classList.remove('hide-items');
        }
    }, [OrderInfo.submitted])
    let reviewTitle = document.getElementById('review-title')
    let reviewBody = document.getElementById('review-body')
    let shippingTitle = document.getElementById('shipping-title')
    let shippingBody = document.getElementById('shipping-body')

    let totalItems = document.getElementById('items-holder');
    const orderHolder = useRef(null);
    const ordersDown = useRef(null);
    const ordersUp = useRef(null);

    const toggleVisibility = () => {
        if (orderHolder.current) {
            orderHolder.current.classList.toggle('hide-items');
            ordersDown.current.classList.toggle('hide-items');
            ordersUp.current.classList.toggle('hide-items');
        }
    }

    const showPromo = () => {
        if (window.location.origin.indexOf("shop") > -1) {
            window.location.href = 'https://www.kawasaki.com/en-us/shop/cart';
        }
        else {
            window.location.href = 'https://akm-public.kawasaki-global-dev.com/en-us/shop/cart';
        }
    }
    const showItems = () => {
        totalItems.classList.toggle('hide-items');
        document.getElementById('items-down').classList.toggle('hide-items');
        document.getElementById('items-up').classList.toggle('hide-items');
    }

    const revertChanges = () => {
        reviewTitle.classList.add('title-group-inactive')
        reviewTitle.classList.remove('title-group-active')
        reviewBody.classList.add('inactive-body')
        shippingBody.classList.remove('inactive-body')
        shippingTitle.classList.add('title-group-active')
        shippingTitle.classList.remove('title-group-inactive')
        shippingTitle.classList.remove('clickable')
    }

    const navigateHelp = () => {
        window.open('/helpcenter', '_blank', 'noopener,noreferrer')
    }

    const handleSignIn = () => {
        if (window.location.origin.indexOf("shop") > -1) {
            window.location.href = 'https://www.kawasaki.com/en-us/my-kawasaki/loginregister/';
        }
        else {
            window.location.href = 'https://akm-public.kawasaki-global-dev.com/en-us/';
        }
    }

    const handleSaveContinueShipToD = () => {
        let selectedVal = $('input[name="shipToD-options"]:checked').val();
        if (selectedVal == "0") {
            //validation for ship to dealer
            if ($('input[name="dealer-ship-name"]:checked').length == 0) {
                setErrorMsgShipToD("Please select a Dealer*");
                return false;
            }

            const dAdd = {
                name: $('input[name="dealer-ship-name"]:checked')[0].getAttribute('dname'),
                address: $('input[name="dealer-ship-name"]:checked')[0].getAttribute('address'),
                city: $('input[name="dealer-ship-name"]:checked')[0].getAttribute('city'),
                state: $('input[name="dealer-ship-name"]:checked')[0].getAttribute('state'),
                zip: $('input[name="dealer-ship-name"]:checked')[0].getAttribute('zip'),
                dealerid: $('input[name="dealer-ship-name"]:checked')[0].getAttribute('dealerid'),
            };
            setShipToDealer(dAdd);
        }
        else {
            setShipToDealer({
                name: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                dealerid: ''
            });
            setSelectedOption(null);
        }
        handleCloseShipToD();
    }

    const showHideDealers = () => {
        debugger;
        if ($('#rdo_shipToD').is(':checked')) {
            $('.row-shipToD').show();
        }
        else {
            $('.row-shipToD').hide();
        }


    }
    const dealerSearch = async () => {
        debugger;
        try {
            let zipcode = $('#txtZip').val().trim();
            if (!zipcode) {
                return;
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({ value1: zipcode, value2: BuyerCoockie, value3: '' })
            };
            const response = await fetch('checkouth/GetShipToDealerOptionsByZip', requestOptions);
            if (response.status != 200) {
                throw new Error(response.statusText);
            }
            else {
                const js = await response.json();
                console.log(js);
                setSelectedOption(null);
                setDealers(js);
            }
        }
        catch (e) {
            console.log(e);
        }

    };

    return (
        <>
            <Container className="checkout-container main-container">
                <Row className="checkout-title-row">
                    <Col className="checkout-title">
                        <h1 className="heading card-main-title">CHECKOUT</h1>
                    </Col>
                    <Col className="hr-left checkout-title-under">
                    </Col>
                </Row>
                <Container className="checkout-container">
                    <Row>
                        <Col lg={8}>
                            <Card className="shipping-card mb-6">

                                <Row sm={12} className="title-group title-group-active" id="shipping-title" onClick={revertChanges}>
                                    {/*                            <Card.Subtitle>STEP 1</Card.Subtitle>*/}
                                    <Card.Title className="vertical-line heading card-main-title active-heading" role="heading"><span className="smaller-text heading">STEP 1 </span>SHIPPING</Card.Title>
                                </Row>
                                <Card.Body id="shipping-body">
                                    <CheckoutStep1 onSuccessFn={onCheckoutStep1Success} id={BuyerCoockie} preloadData={OrderInfo} onReturnUSStates={onUpdateStep2States} shipToDealerID={shipToDealer && shipToDealer.dealerid ? shipToDealer.dealerid : "0"} shipToDealer={shipToDealer} setShowShipToD={setShowShipToD} elektrodeStatus={elektrodeStatus}></CheckoutStep1>
                                </Card.Body>
                                <Row sm={12} className="title-group title-group-inactive" id="review-title">
                                    <Card.Title className="vertical-line heading card-main-title" role="heading"><span className="smaller-text heading">STEP 2 </span>REVIEW AND PAYMENT</Card.Title>
                                </Row>
                                <Card.Body className="inactive-body" id="review-body">
                                    <Row className="review-shipping keep-partial-layout">
                                        <Col sm={5}>
                                            <h4 className="heading review-heading">CONTACT INFORMATION</h4>
                                            <Row>
                                                <Col>
                                                    <p>{Shipping.firstName} {Shipping.lastName}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p>{Shipping.email}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p>{Shipping.phone}</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={5} className="col-small-5">
                                            <h4 className="heading review-heading review-heading-bottom">SHIPPING TO</h4>
                                            {
                                                Shipping.shipping_options == "2" ?
                                                    <Row>
                                                        <Col>
                                                            <p>{Shipping.dropbox}</p>
                                                        </Col>
                                                    </Row> : <></>
                                            }
                                            {
                                               
                                                shipToDealer && shipToDealer.address !== '' ? <><Row>
                                                    <Col>
                                                        {elektrodeOrders.length < OrderInfo.orderitems.length ? <p className="bold">Shipment 1 of 2:</p> : <></>}
                                                    </Col>
                                                </Row>
                                                    <Row>
                                                        <Col>
                                                            <p>{shipToDealer.name}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <p>{shipToDealer.address + ", " + shipToDealer.city + ", " + shipToDealer.state + ", " + shipToDealer.zip}</p>
                                                        </Col>
                                                    </Row>
                                                    <br></br>
                                                </> :
                                                    <></>
                                            }
                                            {
                                                Shipping ? <>
                                                    {<Row>
                                                        <Col>
                                                            {elektrodeOrders.length > 0 && shipToDealer.address !== '' ?
                                                                elektrodeOrders.length < OrderInfo.orderitems.length ? <p className="bold">Shipment 2 of 2:</p> : <p className="bold">Customer Address:</p>
                                                                :
                                                                <></>}
                                                        </Col>
                                                    </Row>}
                                                    <Row>
                                                    <Col>
                                                        <p>{Shipping.address}</p>
                                                    </Col>
                                                </Row>
                                                    <Row>
                                                        <Col>
                                                            <p>{Shipping.cityStateZip}</p>
                                                        </Col>
                                                    </Row></> : <></>
                                            }
                                        </Col>
                                        <Col sm={1} className="col-small-1">
                                            <p className="edit-address clickable" onClick={revertChanges}>Edit</p>
                                        </Col>
                                    </Row>

                                    <CheckoutStep2 USStates={stateOptions} id={BuyerCoockie} shippingInfo={Shipping} onDeliveryOptionChangeFn={onShippingFeeChangeFn} freightFee={freightFee} priceTotal={OrderInfo.dTotal} dealerid={shipToDealer.dealerid}></CheckoutStep2>

                                </Card.Body>
                            </Card>
                        </Col>

                        <Col lg={4} className="checkout-summary">
                            <div className="sticky" >
                                <Card className="signin-card" id="divSignin">
                                    <Card.Body>
                                        <Row className="keep-layout">
                                            <Col lg={8} sm={8} className="signin-text">
                                                <p>Sign in for a faster checkout</p>
                                            </Col>
                                            <Col lg={4} sm={4} className="signin-btn">
                                                <Button variant="secondary" onClick={handleSignIn}>
                                                    <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill green" viewBox="0 0 16 16" role="presentation">
                                                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                    </svg>
                                                    SIGN IN
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <Card className="summary-card mb-6" >
                                    <Card.Body>
                                        <Row className="title-group summary-title keep-layout checkout-title-row">
                                            <Col className="checkout-title">
                                                <Card.Title className="heading" role="heading">ORDER SUMMARY</Card.Title>
                                            </Col>
                                            <Col className="checkout-title-under">
                                                <Card.Subtitle className="smaller-text edit-button underline right-text" onClick={showPromo}>Edit</Card.Subtitle>
                                            </Col>
                                        </Row>
                                        <Row className="keep-layout">
                                            <Col lg={6} md={8} sm={6}>
                                                <p>Subtotal</p>
                                            </Col>
                                            <Col lg={6} md={4} sm={6}>
                                                <p className="right-text heading" id="value">{OrderInfo.subtotal}</p>
                                            </Col>
                                        </Row>
                                        <Row className="keep-layout">
                                            <Col lg={6} md={8} sm={6}>
                                                <p>Shipping</p>
                                            </Col>
                                            <Col lg={6} md={4} sm={6}>
                                                <p className="right-text heading" id="shipping">{OrderInfo.shippingFee}</p>
                                            </Col>
                                        </Row>
                                        <Row className="keep-layout">
                                            <Col lg={6} md={8} sm={6}>
                                                <p>Tax</p>
                                            </Col>
                                            <Col lg={6} md={4} sm={6}>
                                                <p className="right-text heading" id="tax">{OrderInfo.tax}</p>
                                            </Col>
                                        </Row>
                                        <Row className="keep-layout">
                                            <Col lg={6} md={8} sm={6}>
                                                <p>Promo Applied: <span id="coupon">{OrderInfo.promoCode != "" ? OrderInfo.promoCode : ""}</span></p>
                                            </Col>


                                            {OrderInfo.promoCode != "" ? <Col lg={6} md={4} sm={6} className="promoText" id="promoTextInput">
                                                <p className="right-text heading" style={{ color: 'red' }}>{OrderInfo.promoDiscount}</p>
                                            </Col> : <Col lg={6} md={4} sm={6}>
                                                <p className="underline right-text" onClick={showPromo}>+Add</p>
                                            </Col>}

                                        </Row>
                                        {OrderInfo.promoCodeDesc != "" &&
                                            <Row className="keep-layout">
                                                <Col sm={12}>
                                                    <p>Disclaimer:</p>
                                                </Col>
                                                <Col sm={12}>
                                                    <p className="heading">{OrderInfo.promoCodeDesc}</p>
                                                </Col>

                                            </Row>}
                                        <hr></hr>
                                        <Row className="title-group summary-title keep-layout">
                                            <Col sm={6}>
                                                <Card.Title className="heading" role="heading">TOTAL</Card.Title>
                                            </Col>
                                            <Col sm={6}>
                                                <Card.Title className="right-text heading">{OrderInfo.total}</Card.Title>
                                            </Col>
                                        </Row>
                                        <hr></hr>
                                        {shipToDealer.address !== '' ?
                                            <>
                                                <Row className="keep-layout">

                                                    {
                                                        elektrodeOrders.length < OrderInfo.orderitems.length ?
                                                            <Col sm={6}> <p className="bold underline heading  items-list" onClick={showItems}>SHIPMENT 1 of 2</p></Col> :
                                                            <Col sm={6}>
                                                                <p className="bold underline heading  items-list" onClick={showItems}>{OrderInfo.orderitems ? Object.keys(OrderInfo.orderitems).length : 0} ITEM(S)</p>
                                                            </Col>
                                                    }


                                                    <Col sm={6}>
                                                        <p className="right-text" onClick={showItems}>
                                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="items-down" className="hide-items bi bi-caret-down-fill items-list" viewBox="0 0 16 16" role="presentation">
                                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                            </svg>
                                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="items-up" className="bi bi-caret-up-fill items-list" viewBox="0 0 16 16" role="presentation">
                                                                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                            </svg>
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <div id="items-holder" className="images-holder">
                                                    <Row className="dealer-ship-info keep-layout">
                                                        <Col sm={12}>
                                                            <p className="shipping-to">Shipping to:</p>
                                                        </Col>
                                                        <Col sm={9} lg={8} xl={9} xs={8}>
                                                            <p className="item-title item-name">{shipToDealer ? shipToDealer.name : ""}</p>
                                                            <p className="item-desc quantity">{shipToDealer ? shipToDealer.address + ", " + shipToDealer.city + ", " + shipToDealer.state + ", " + shipToDealer.zip : ""}</p>
                                                        </Col>
                                                        <Col sm={3} lg={4} xl={3} xs={4}>
                                                            <Button variant="secondary" onClick={() => setShowShipToD(true)}>
                                                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill green" viewBox="0 0 16 16" role="presentation">
                                                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                                </svg>
                                                                EDIT
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        elektrodeOrders.map(d =>
                                                            <Row className="item-holder">
                                                                <Col sm={3} className="summary-img-col">
                                                                    <img src={d.img} alt="Product" className="item-image"></img>
                                                                </Col>
                                                                <Col sm={7}>
                                                                    <p className="item-title item-name">{d.description}</p>
                                                                    <p className="item-desc quantity">Qty: {d.qty}</p>
                                                                    <p className="item-desc item-id">SKU: {d.partID}</p>
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <p className="right-text heading price">{d.unitPrice}</p>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    }
                                                </div>
                                                <Row className="keep-layout" style={{ marginTop: 20 }}>
                                                    {
                                                        elektrodeOrders.length < OrderInfo.orderitems.length ?
                                                            <>
                                                                <Col sm={6} onClick={toggleVisibility}>
                                                                    <p className="bold underline heading items-list-second">SHIPMENT 2 of 2</p>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <p className="right-text" onClick={toggleVisibility}>
                                                                        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" ref={ordersDown} className="hide-items bi bi-caret-down-fill items-list" viewBox="0 0 16 16" role="presentation">
                                                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                                        </svg>
                                                                        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" ref={ordersUp} className="bi bi-caret-up-fill items-list" viewBox="0 0 16 16" role="presentation">
                                                                            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                                        </svg>
                                                                    </p>
                                                                </Col>
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </Row>
                                                <div ref={orderHolder}>
                                                    {elektrodeOrders.length < OrderInfo.orderitems.length && Shipping.address ? 
                                                        <Col sm={12}>
                                                            <Row className="dealer-ship-info keep-layout">
                                                                <Col sm={12}>
                                                                    <p className="shipping-to">Shipping to:</p>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <p className="item-desc quantity">{Shipping.address}</p>
                                                                    <p className="item-desc quantity">{Shipping.cityStateZip}</p>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    :  
                                                        <></>
                                                    }
                                                    {
                                                        OrderInfo.orderitems ? OrderInfo.orderitems.map(d => {
                                                            return (
                                                                !d.isElektrode ?
                                                                    <Row className="item-holder">
                                                                        <Col sm={3} className="summary-img-col">
                                                                            <img src={d.img} alt="Product" className="item-image"></img>
                                                                        </Col>
                                                                        <Col sm={7}>
                                                                            <p className="item-title item-name">{d.description}</p>
                                                                            <p className="item-desc quantity">Qty: {d.qty}</p>
                                                                            <p className="item-desc item-id">SKU: {d.partID}</p>
                                                                        </Col>
                                                                        <Col sm={2}>
                                                                            <p className="right-text heading price">{d.unitPrice}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    : <></>
                                                            )
                                                        }) : <></>

                                                    }
                                                </div>
                                                <hr></hr>
                                            </>
                                            :
                                            <>
                                                <Row className="keep-layout">
                                                    <Col sm={6}>
                                                        <p className="bold underline heading  items-list" onClick={showItems}>{OrderInfo.orderitems ? Object.keys(OrderInfo.orderitems).length : 0} ITEM(S)</p>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <p className="right-text" onClick={showItems}>
                                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="items-down" className="hide-items bi bi-caret-down-fill items-list" viewBox="0 0 16 16" role="presentation">
                                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                            </svg>
                                                            <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="items-up" className="bi bi-caret-up-fill items-list" viewBox="0 0 16 16" role="presentation">
                                                                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                            </svg>
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <div id="items-holder" className="images-holder">
                                                    {
                                                        OrderInfo.orderitems ? OrderInfo.orderitems.map(d =>
                                                            <Row className="item-holder">
                                                                <Col sm={3} className="summary-img-col">
                                                                    <img src={d.img} alt="Product" className="item-image"></img>
                                                                </Col>
                                                                <Col sm={7}>
                                                                    <p className="item-title item-name">{d.description}</p>
                                                                    <p className="item-desc quantity">Qty: {d.qty}</p>
                                                                    <p className="item-desc item-id">SKU: {d.partID}</p>
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <p className="right-text heading price">{d.unitPrice}</p>
                                                                </Col>
                                                            </Row>
                                                        ) : <></>
                                                    }
                                                    <hr></hr>
                                                </div>
                                            </>
                                        }
                                        <Row>
                                            <Col sm={12}>
                                                <p className="bold underline heading need-help" onClick={navigateHelp}>NEED HELP?</p>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>

                    </Row>
                    <Modal className="loader-div" id="loader" show={pop} onHide={handleClose} size="lg" centered>
                        <PulseLoader color="#66cc33" size="20px" margin="12px" />
                    </Modal>
                    <Modal show={showShipToD} onHide={handleCloseShipToD} size="lg" centered backdrop={'static'} keyboard={false}>
                        <Modal.Header closeVariant="white"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                            <Modal.Title>Ship To Dealer Option</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{fontSize: 18}}>
                            <span className="bold" style={{ display: 'unset' }}>Elektrode® electric bike orders are eligible to ship to your dealership.</span>
                            <br />
                            Your dealer will configure and assemble your eBike for first time use at <span className="bold" style={{ display: 'unset' }}>no charge</span>.
                        </Modal.Body>
                        <Modal.Body className="dealer-ship-box">
                            <h5 style={{ textTransform: 'uppercase' }}>Would you like to ship to dealer?</h5>
                            <Row className="keep-layout">
                                <Col sm={6} className="radio-right">
                                    <Form.Check className="radio-ship" defaultChecked label="Yes, Ship to Dealer" name="shipToD-options" type="radio" id="rdo_shipToD" value={0} onClick={showHideDealers} />
                                    {<p className="error-msg" id="error-msg-one">{errorMsgShipToD}</p>}
                                </Col>
                                <Col sm={6} className="radio-left">
                                    <Form.Check className="radio-ship" label="No, Ship to My Address" name="shipToD-options" type="radio" id="rdo_shopToMe" value={1} onClick={showHideDealers} />
                                </Col>
                            </Row>
                            <Row className="keep-layout row-shipToD">
                                <Col lg={5} sm={10}>
                                    <FloatingLabel controlId="floatingAddress" label="Enter Your Address Or Zip Code*" className="mb-3" >
                                        <Form.Control type="text" id="txtZip" placeholder="Enter Your Address Or Zip Code*" className={`form-control`} maxLength="100" />
                                    </FloatingLabel>
                                </Col>
                                <Col lg={1} sm={2}>
                                    <Button className="search-btn confirm-dealers-search" id="searchBtn" style={{ marginTop: '7px' }} onClick={dealerSearch}>
                                        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16" role="presentation">
                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                        </svg>
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="keep-layout row-shipToD">
                                <Col lg={10} sm={12}>
                                    <Row className={dealers ? "dealer-ship-holder" : ""}>
                                        {dealers ?
                                            dealers.map((d, idx) =>
                                                
                                                <Col sm={6} className="radio-left">
                                                    <Form.Check className="radio-ship" label={d.name} name="dealer-ship-name" type="radio" id={"dealer" + idx} value={idx}
                                                        address={d.address} city={d.city} state={d.state} zip={d.zip} dname={d.name} dealerid={d.dealerKey} checked={selectedOption === idx} onChange={(e) => setSelectedOption(Number(e.target.value))} />
                                                    <p>
                                                        {d.address}, <br />
                                                        {d.city}, {d.state}, {d.zip}
                                                    </p>
                                                </Col>
                                            ) : <></>}
                                    </Row>
                                </Col>
                            </Row>

                        </Modal.Body>
                        <Modal.Footer>
                            <Row className="modal-footer-buttons">
                                <Col sm={12}>
                                    <Button variant="secondary" onClick={handleSaveContinueShipToD}>
                                        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill green" viewBox="0 0 16 16" role="presentation">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg>
                                        CONTINUE
                                    </Button>
                                </Col>
                            </Row>
                        </Modal.Footer>
                    </Modal>
                </Container>
            </Container>
        </>
    );
}