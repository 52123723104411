import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";



export default function SessionExpired() {
    const [msg, setMsg] = useState('');
    const location = useLocation();
    useEffect(() => {
        setMsg(location.state.id == 1 ? "YOUR CART IS EMPTY" :"YOUR CART HAS EXPIRED ");
    }, [location.state.id])

    return (
        <Container className="checkout-container main-container">
            <Container className="checkout-container">

                <Card className="confirmation-card mb-6 box-shadow">
                    <Card.Body>
                        <Row className="title-group confirmation-heading">
                            <Card.Title className="vertical-line heading card-main-title confirm-title" role="heading">{msg}</Card.Title>
                        </Row>
                        <Row className="confirm-text first-confirm">
                            <p className="confirmation-text" aria-level="1" role="heading">Please return back to Kawasaki.com</p>
                        </Row>
                        <Row className="confirm-button">
                            <Button variant="primary" className="confirm-complete" href={window.location.origin.indexOf("shop") == -1 ? "https://akm-public.kawasaki-global-dev.com/en-us/" : "https://kawasaki.com"}>
                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16" role="presentation">
                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                </svg
                                >BACK TO <span className="link-decoration">KAWASAKI.COM</span>
                            </Button>
                        </Row>
                    </Card.Body>
                </Card>

            </Container>
        </Container>
        )
}